import React from "react";
import cl from "classnames";

// Scss
import styles from "./MenuGroup.module.scss";

// Typescript
import { MenuGroupProps } from "./MenuGroup.props";

const MenuGroup: React.FC<MenuGroupProps> = ({ name, children, ...props }): JSX.Element => {
  return (
    <li className={cl(styles["menu-group"])} {...props}>
      <span className={cl(styles["menu-group__title"])}>{name}</span>
      <ul className={cl(styles["menu-group__links"])}>{children}</ul>
    </li>
  );
};

export default React.memo(MenuGroup);
