import React, { useCallback, useMemo } from "react";
import cl from "classnames";

// Scss
import styles from "./DigitCodeField.module.scss";

// Typescript
import { DigitCodeFieldProps } from "./DigitCodeField.props";

const DigitCodeField: React.ForwardRefRenderFunction<HTMLUListElement, DigitCodeFieldProps> = (
  { codeLength, value = "", onChange, className, ...props },
  ref,
): JSX.Element => {
  const handleDigitFieldChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const target = evt.target;
      let targetValue = target.value;
      const isTargetValueDigit = new RegExp(/^\d+$/).test(targetValue);

      if (!isTargetValueDigit && targetValue !== "") {
        return;
      }

      targetValue = isTargetValueDigit ? targetValue : " ";
      const newValue = value.substring(0, index) + targetValue + value.substring(index + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      let nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
      if (nextElementSibling?.nodeName === "SPAN") {
        nextElementSibling = nextElementSibling.nextElementSibling as HTMLInputElement | null;
      }

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    },
    [value, onChange],
  );

  const handleDeleteKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const target = evt.target as HTMLInputElement;

    if (evt.key !== "Backspace" || target.value !== "") {
      return;
    }

    let previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
    if (previousElementSibling?.nodeName === "SPAN") {
      previousElementSibling = previousElementSibling.previousElementSibling as HTMLInputElement | null;
    }

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const handleInputFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.target.setSelectionRange(0, evt.target.value.length);
  };

  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < codeLength; i++) {
      const char = valueArray[i];

      if (new RegExp(/^\d+$/).test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, codeLength]);

  const DigitBoxesComponents = useMemo(
    () =>
      valueItems.map((digit, index) => {
        return (
          <input
            key={index + ""}
            type='text'
            maxLength={1}
            minLength={1}
            value={digit}
            className={cl(styles["code-field__input"])}
            onKeyDown={handleDeleteKeyDown}
            onChange={(evt) => handleDigitFieldChange(evt, index)}
            onFocus={handleInputFocus}
            autoComplete='one-time-code'
          />
        );
      }),
    [handleDigitFieldChange, valueItems],
  );

  if (codeLength === 6) {
    const separatorElement = (
      <span key='-' className={cl(styles["code-field__separator"])}>
        -
      </span>
    );

    DigitBoxesComponents.splice(3, 0, separatorElement);
  }

  return (
    <ul ref={ref} className={cl(styles["code-field"], className)} {...props}>
      {DigitBoxesComponents}
    </ul>
  );
};

export default React.forwardRef<HTMLUListElement, DigitCodeFieldProps>(DigitCodeField);
