import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PopupProvider } from "react-popup-manager";
import { SnackbarProvider } from "notistack";

// Material UI
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./mui-theme";

// Redux
import { setupStore } from "store";

import "styles/variables.scss";
import { App } from "./App";

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Не удалось найти root элемент");

const root = ReactDOM.createRoot(rootElement);

export const store = setupStore();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={5000}>
        <ThemeProvider theme={theme}>
          <PopupProvider>
            <App />
          </PopupProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>,
);
