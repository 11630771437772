import axios, { AxiosInstance } from "axios";

export interface SuggestedOrganization {
  value: string;
  unrestricted_value: string;
  data: {
    inn: string;
    kpp: string;
    ogrn: string;
    okpo: string;
    address: {
      value: string;
      unrestricted_value: string;
    };
    name: {
      full_with_opf: string;
      short_with_opf: string;
    };
  };
}

export interface SuggestedBank {
  value: string;
  unrestricted_value: string;
  data: {
    bic: string;
    correspondent_account: string;
    address: {
      value: string;
      unrestricted_value: string;
    };
    "name": {
      payment: string;
      short: string;
    };
  };
}

class DaDataAPI {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${process.env.REACT_APP_DADATA_TOKEN}`,
        "X-Secret": process.env.REACT_APP_DADATA_SECRET || "",
      },
    });
  }

  autoCompleteFullName(query: string): Promise<{ suggestions: { value: string; data: Record<string, string> }[] }> {
    return this.axios.post("/suggest/fio", { query }).then((res) => res.data);
  }

  findOrganization(query: string): Promise<{ suggestions: SuggestedOrganization[] }> {
    return this.axios.post("/findById/party", { query, status: ["ACTIVE"] }).then((res) => res.data);
  }

  findBank(query: string): Promise<{ suggestions: SuggestedBank[] }> {
    return this.axios.post("/findById/bank", { query, status: ["ACTIVE"] }).then((res) => res.data);
  }
}

export default new DaDataAPI();
