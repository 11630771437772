import React, { useRef } from "react";
import cl from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import { useNavigate } from "react-router";

// Material UI
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";

// Services
import { SessionsAPI } from "services/Passport";

// Images
import {
  LogoutOutlinedIcon,
  ManageAccountsOutlinedIcon,
  AddOutlinedIcon,
  HttpsOutlinedIcon,
  FeedbackOutlinedIcon,
  GroupsOutlinedIcon,
} from "assets/icons";

// Scss
import styles from "./ProfileModal.module.scss";

// Redux
import { useAppSelector } from "hooks/redux";

// Typescript
import { ProfileModalProps } from "./ProfileModal.props";

export const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose, ...props }): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { user } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const handlePopupClose = () => {
    if (onClose) onClose({}, "backdropClick");
  };

  useOnClickOutside(modalRef, handlePopupClose);

  const handleLogoutClick = async () => {
    await SessionsAPI.logout().then(() => window.location.reload());
  };

  const handleNavigateButtonClick = (url: string) => () => {
    if (onClose) onClose({}, "backdropClick");

    if (url.includes("https") || url.includes("http")) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  return (
    <Popover
      ref={modalRef}
      open={isOpen ?? false}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ marginTop: "8px", borderRadius: "8px" }}
      TransitionComponent={Fade}
      onClose={onClose}
      {...props}
    >
      <div className={cl(styles["modal"])}>
        <div className={cl(styles["modal__profile"])}>
          <img className={cl(styles["modal__profile-image"])} src={user?.image || ""} />
          <span className={cl(styles["modal__profile-fullName"])}>
            {user?.name ?? "Неизвестно"} {user?.surname ?? "Неизвестно"}
          </span>
          <span className={cl(styles["modal__profile-email"])}>{user?.email ?? "Неизвестно"}</span>
        </div>
        <i className={cl(styles["modal__section-separator"])} />
        <div className={cl(styles["modal__actions"])}>
          <button
            type='button'
            className={cl(styles["modal__button"])}
            onClick={handleNavigateButtonClick("/settings/account")}
          >
            <ManageAccountsOutlinedIcon className={cl(styles["modal__button-icon"])} />
            <span className={cl(styles["modal__button-label"])}>Настроить профиль</span>
          </button>
          <button
            type='button'
            className={cl(styles["modal__button"])}
            onClick={handleNavigateButtonClick("/settings/security")}
          >
            <HttpsOutlinedIcon className={cl(styles["modal__button-icon"])} />
            <span className={cl(styles["modal__button-label"])}>Безопасность</span>
          </button>
          <button
            type='button'
            className={cl(styles["modal__button"])}
            onClick={handleNavigateButtonClick("https://forms.yandex.ru/cloud/635651235d2a06a26ce230e2/")}
          >
            <FeedbackOutlinedIcon className={cl(styles["modal__button-icon"])} />
            <span className={cl(styles["modal__button-label"])}>Обратная связь</span>
          </button>
          {!user?.organizations[0] && (
            <button
              type='button'
              className={cl(styles["modal__button"])}
              onClick={handleNavigateButtonClick("/create-organization")}
            >
              <AddOutlinedIcon className={cl(styles["modal__button-icon"])} />
              <span className={cl(styles["modal__button-label"])}>Добавить организацию</span>
            </button>
          )}
        </div>
        {user?.organizations[0] && (
          <>
            <i className={cl(styles["modal__section-separator"])} />
            <div className={cl(styles["modal__profile"])}>
              <img className={cl(styles["modal__profile-image"])} src={user?.organizations[0].photo || ""} />
              <span className={cl(styles["modal__profile-fullName"])}>
                {user?.organizations[0].details.shortName ?? "Неизвестно"}
              </span>
              <span className={cl(styles["modal__profile-email"])}>
                {user?.organizations[0].details.email ?? "Неизвестно"}
              </span>
            </div>
            <i className={cl(styles["modal__section-separator"])} />
            <div className={cl(styles["modal__actions"])}>
              <button
                type='button'
                className={cl(styles["modal__button"])}
                onClick={handleNavigateButtonClick("/settings/organization")}
              >
                <GroupsOutlinedIcon className={cl(styles["modal__button-icon"])} />
                <span className={cl(styles["modal__button-label"])}>Профиль организации</span>
              </button>
            </div>
          </>
        )}
        <i className={cl(styles["modal__section-separator"])} />
        <button
          type='button'
          onClick={handleLogoutClick}
          className={cl(styles["modal__button"], styles["modal__button_light"])}
        >
          <LogoutOutlinedIcon className={cl(styles["modal__button-icon"])} />
          <span className={cl(styles["modal__button-label"])}>Выйти из аккаунта</span>
        </button>
      </div>
    </Popover>
  );
};
