import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import _get from "lodash/get";

// Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

// Services
import { ApplicationsAPI } from "services";

// Images
import { DeleteOutlineOutlinedIcon, MoreVertOutlinedIcon } from "assets/icons";
// Modals
import { BaseModal, BaseModalButton } from "components/Modals";

// Typescript
import { ApplicationsTableProps, applicationsTableColumns } from "./ApplicationsTable.props";
import { IApplication } from "types/models";

export const ApplicationsTable: React.FC<ApplicationsTableProps> = ({
  applications,
  maxCount,
  onTableChange,
}): JSX.Element => {
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);

  const handlePageChange = (evt: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onTableChange(currentPage);
  }, [currentPage, onTableChange]);

  const handleModalOpen = useCallback(
    (application: IApplication) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSelectedRowsIds([application.id]);

      const target = evt.target as HTMLElement;
      const anchorEl = target.closest("svg") as SVGSVGElement;

      const buttons: BaseModalButton[] = [];

      buttons.push({
        key: "1",
        text: "Удалить",
        icon: DeleteOutlineOutlinedIcon,
        onClick: () => {
          ApplicationsAPI.delete(application.id).then(async () => {
            enqueueSnackbar(`Приложение "${application.id}" успешно удалено и больше не имеет доступа к API`, {
              variant: "success",
            });

            await onTableChange(currentPage);
          });
        },
      });

      popupManager.open(BaseModal, {
        anchorEl,
        buttons,
        key: "applications-modal",
        onClose: () => setSelectedRowsIds([]),
      });
    },
    [popupManager, enqueueSnackbar, onTableChange, currentPage],
  );

  const ApplicationsElementsRows = useMemo(
    () =>
      applications.map((application) => (
        <TableRow color='hover' selected={selectedRowsIds.includes(application.id)} key={application.id}>
          {applicationsTableColumns.map(({ id, format, ...props }, idx) => {
            const value = _get(application, id);
            return (
              <TableCell key={id + idx} {...props}>
                {format ? format(value) : value}
              </TableCell>
            );
          })}
          <TableCell align='center' style={{ width: 48 }}>
            <IconButton
              disableFocusRipple
              type='button'
              disableRipple
              onClick={handleModalOpen(application)}
              sx={{ padding: 0 }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [handleModalOpen, selectedRowsIds, applications],
  );

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {applicationsTableColumns.map(({ id, label, format, ...props }, idx) => (
                <TableCell key={id + idx} {...props}>
                  {format ? label : label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{ApplicationsElementsRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={maxCount}
        rowsPerPage={10}
        page={currentPage}
        rowsPerPageOptions={[10]}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to, count }) => {
          return "Сессии " + from + "-" + to + " из " + count;
        }}
      />
    </>
  );
};
