import React from "react";

// Images
import { PersonOffOutlinedIcon, PersonOutlineOutlinedIcon } from "assets/icons";

// Typescript
import { Column, GenericTableProps } from "blocks";
import { IUser } from "types/models";

export interface UsersTableProps extends GenericTableProps {
  users: IUser[];
}

export const usersTableColumns: Column[] = [
  { id: "id", label: "ID", align: "center" },
  { id: "email", label: "E-mail" },
  {
    id: "self",
    label: "ФИО",
    format: (value) => {
      return `${(value as IUser).name ?? ""} ${(value as IUser).surname ?? ""} ${(value as IUser).patronymic ?? ""}`;
    },
  },
  {
    id: "isBlocked",
    label: "Статус",
    align: "center",
    style: { display: "flex", alignItems: "center", justifyContent: "center" },
    format: (value) => {
      return (value as boolean) ? (
        <PersonOffOutlinedIcon color='error' />
      ) : (
        <PersonOutlineOutlinedIcon color='success' />
      );
    },
  },
];
