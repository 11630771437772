import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// Services
import { UsersAPI } from "services/Passport";

// Components
import { PasswordInput } from "components/Inputs";
import { PopupLayout } from "layouts";

// Scss
import styles from "./PasswordChange.module.scss";

// Utils
import { changePasswordSchema } from "utils/Schemas";

// Typescript
import { PasswordChangeFields, PasswordChangeProps } from "./PasswordChange.props";

export const PasswordChange: React.FC<PasswordChangeProps> = ({
  user,
  isOpen,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<PasswordChangeFields>({ mode: "all", resolver: yupResolver(changePasswordSchema) });
  const [isSubmitting, setFormSubmitting] = useState(false);

  const onSubmitError = (error: AxiosError) => {
    const message = error.response?.data.message ?? "Неизвестная ошибка сервера";

    if (error.response?.status === 400) {
      setError(error.response.data.field, message);
      return;
    }
  };

  const onFormSubmit: SubmitHandler<PasswordChangeFields> = async (values) => {
    setFormSubmitting(true);

    return UsersAPI.changePassword(user.id, { ...values })
      .then(async () => {
        onSuccessServerResponse();
        onClose();
      })
      .catch(onSubmitError)
      .finally(() => setFormSubmitting(false));
  };

  return (
    <PopupLayout title='Изменение пароля' isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["form"])}>
        <PasswordInput
          variant='outlined'
          label='Старый пароль'
          {...register("currentPassword")}
          error={!!errors["currentPassword"]}
          helperText={errors["currentPassword"]?.message}
        />
        <PasswordInput
          variant='outlined'
          label='Новый пароль'
          {...register("newPassword")}
          error={!!errors["newPassword"]}
          helperText={errors["newPassword"]?.message}
        />
        <div className={cl(styles["form__buttons"])}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Сохранить
          </LoadingButton>
          <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
            Отменить
          </Button>
        </div>
      </form>
    </PopupLayout>
  );
};
