import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { IApplication, IApplicationWithPassword } from "types/models";
import { MultiResponse } from "types/MultiResponse";
import { ApplicationFormFields } from "popups";

class ApplicationsAPI extends GlobalAPI {
  create(organizationId: string, applicationData: ApplicationFormFields): Promise<IApplicationWithPassword> {
    return this.axios.post(`/organizations/${organizationId}/applications`, applicationData).then((res) => res.data);
  }

  findAllByOrganizationId(organizationId: string, page: number, amount = 10): Promise<MultiResponse<IApplication>> {
    return this.axios
      .get(`/organizations/${organizationId}/applications`, { params: { page, amount } })
      .then((res) => res.data);
  }

  delete(applicationId: string): Promise<MultiResponse<IApplication>> {
    return this.axios.delete(`/applications/${applicationId}`).then((res) => res.data);
  }
}

export default new ApplicationsAPI();
