import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { MultiResponse } from "types/MultiResponse";
import { IOrganization, IOrganizationEmployee } from "types/models";

class EmployeesAPI extends GlobalAPI {
  async invite(organizationId: string, payload: { acl: string[]; email: string }): Promise<IOrganization> {
    return this.axios.post(`/organizations/${organizationId}/employees`, payload).then((res) => res.data);
  }

  async findAllByOrganizationId(
    organizationId: string | number,
    searchText: string,
    page: number,
    amount: number,
  ): Promise<MultiResponse<IOrganizationEmployee>> {
    return this.axios
      .get(`/organizations/${organizationId}/employees`, { params: { searchText, page, amount } })
      .then((res) => res.data);
  }

  async findInvitations(): Promise<MultiResponse<IOrganizationEmployee>> {
    return this.axios.get(`/organizations-employees/invitations`).then((res) => res.data);
  }

  async updateInvitation(payload: {
    organizationId: string;
    status: "accept" | "decline";
  }): Promise<MultiResponse<IOrganizationEmployee>> {
    return this.axios.patch(`/organizations-employees/invitations`, payload).then((res) => res.data);
  }

  async updateEmployeeACL(
    employeeId: string | number,
    selectedACL: string[],
  ): Promise<MultiResponse<IOrganizationEmployee>> {
    return this.axios.patch(`/organizations-employees/${employeeId}/acl`, selectedACL).then((res) => res.data);
  }

  async deleteEmployee(employeeId: string | number): Promise<MultiResponse<IOrganizationEmployee>> {
    return this.axios.delete(`/organizations-employees/${employeeId}`).then((res) => res.data);
  }
}

export default new EmployeesAPI();
