import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import _isEqual from "lodash/isEqual";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import { DaDataAPI } from "services/External";
import { UsersAPI } from "services/Passport";

// Layouts
import { PopupLayout } from "layouts";

// Schemas
import { changeProfileSchema } from "utils/Schemas";
import { parseStringToPhone } from "utils/Phone";

// Scss
import styles from "./ProfileChange.module.scss";

// Typescript
import { ProfileChangeProps, ProfileChangeFields } from "./ProfileChange.props";

export const ProfileChange: React.FC<ProfileChangeProps> = ({
  userId,
  isOpen,
  defaultValues,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  // Массив с данными для автозаполнения ФИО
  const [fullNameSuggestions, setFullNameSuggestions] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm<ProfileChangeFields>({ mode: "all", resolver: yupResolver(changeProfileSchema), defaultValues });
  const { fullName, phone } = useWatch({ control });
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSuggestionsLoading, setSuggestionsStatus] = useState(false);

  const onRegisterRequestError = (error: AxiosError) => {
    const message = error.response?.data.message;

    if (error.response?.status === 400) {
      const errorField = error.response?.data.field as keyof ProfileChangeFields;
      setError(errorField, { message: message, type: "validate" });
    } else {
      enqueueSnackbar(message ?? "Неизвестная ошибка сервера", { variant: "error" });
    }
  };

  const onFormSubmit: SubmitHandler<ProfileChangeFields> = async (values) => {
    if (_isEqual(values, defaultValues)) {
      onClose();
      return;
    }

    const nameData = await DaDataAPI.autoCompleteFullName(values.fullName ?? "");
    const result = nameData.suggestions[0];
    const separatedFullName: string[] = (values.fullName ?? "").split(" ");

    const name = result ? result.data.name : separatedFullName[1];
    const surname = result ? result.data.surname : separatedFullName[0];
    const patronymic = result ? result.data.patronymic : separatedFullName.splice(2).join(" ");

    setSubmitting(true);

    return UsersAPI.update(userId, { ...values, name, patronymic, surname })
      .then(() => {
        onSuccessServerResponse();
        onClose();
      })
      .catch(onRegisterRequestError)
      .finally(() => setSubmitting(false));
  };

  // Функция загрузки информации о введенных данных ФИО
  // (для поиска вариантов автозаполнения)
  const loadFullNameSuggestions = useCallback(async () => {
    setSuggestionsStatus(true);

    if ((fullName ?? "").length % 2 === 0) {
      const { suggestions } = await DaDataAPI.autoCompleteFullName(fullName ?? "");
      setFullNameSuggestions(suggestions.map((suggestion) => suggestion.value));
    }

    setSuggestionsStatus(false);
  }, [fullName]);

  useEffect(() => {
    loadFullNameSuggestions();
  }, [loadFullNameSuggestions]);

  const handleFullNameFieldChange = (_: React.SyntheticEvent, value: string | null) => {
    setValue("fullName", value);
  };

  return (
    <PopupLayout isOpen={isOpen} title='Редактирование информации' onClose={onClose}>
      <form onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["popup__form"])}>
        <Autocomplete
          required
          title='ФИО'
          value={fullName ?? ""}
          loadingText='Идет поиск'
          options={fullNameSuggestions}
          loading={isSuggestionsLoading}
          className={cl(styles["form__suggestions-field"])}
          {...register("fullName")}
          onChange={handleFullNameFieldChange}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              {...register("fullName")}
              label='ФИО'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isSuggestionsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <TextField
          required
          label='E-mail'
          type='text'
          {...register("email")}
          error={!!errors["email"]}
          helperText={errors["email"]?.message}
        />
        <TextField
          required
          type='text'
          label='Телефон'
          {...register("phone")}
          value={parseStringToPhone(phone ?? "").formattedNumber}
          error={!!errors["phone"]}
          helperText={errors["phone"]?.message}
        />
        <div className={cl(styles["popup__buttons"])}>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Сохранить
          </LoadingButton>
          <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
            Отменить
          </Button>
        </div>
      </form>
    </PopupLayout>
  );
};
