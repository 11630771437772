import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Components
import { ReactComponent as SuccessSVG } from "assets/images/success.svg";
import { ReactComponent as ErrorSVG } from "assets/images/error.svg";

// Scss
import styles from "./StatusPage.module.scss";

// Typescript
import { IStatusPage } from "./StatusPage.props";

export const StatusPage: React.FC<IStatusPage> = ({ title, subtitle, status, buttonText, onClick }): JSX.Element => {
  return (
    <main className={cl(styles["content"])}>
      {status === "success" && <SuccessSVG className={cl(styles["content__icon"])} />}
      {status === "error" && <ErrorSVG className={cl(styles["content__icon"])} />}
      <h1 className={cl(styles["content__title"])}>{title}</h1>
      <span className={cl(styles["content__subtitle"])}>{subtitle}</span>
      <Button
        size='large'
        type='button'
        variant='contained'
        className={cl(styles["content__button"])}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </main>
  );
};
