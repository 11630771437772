import { createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";

export const theme = createTheme(
  {
    palette: {
      "primary": {
        "main": "#272154",
        "light": "#004F9F",
        "dark": "#003E7A",
      },
      "secondary": {
        "main": "#004F9F",
        "light": "#007CF9",
        "dark": "#006BD7",
      },
      "error": {
        "main": "#C30000",
      },
      "success": {
        "main": "#0A9C44",
      },
    },
    typography: {
      fontFamily: [
        "SF Pro Display",
        "Roboto",
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          "root": {
            textTransform: "none",
          },
          "contained": {
            boxShadow: "none",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "#004F9F",
              boxShadow: "none",
            },
            "&:focus": {
              backgroundColor: "#004F9F",
              boxShadow: "none",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderColor: "#969696",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          "notchedOutline": {
            borderColor: "#969696",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          "list": { padding: 0, minWidth: "120px" },
        },
      },
      MuiSvgIcon: {
        "styleOverrides": {
          root: {
            transition: "all 0.2s linear",
          },
        },
      },
      MuiIconButton: {
        "styleOverrides": {
          "root": {
            color: "#969696",
            ":hover": { color: "#4D4F55" },
            ":active": { color: "#111214" },
          },
        },
      },
      MuiTableCell: {
        "styleOverrides": {
          "head": { color: "#969696", fontWeight: 500, background: "none" },
          "root": { padding: "12px 24px" },
        },
      },
      MuiTableRow: {
        "variants": [
          {
            props: { "color": "hover" },
            style: {
              cursor: "pointer",
              "transition": "all 0.2s linear",
              ":hover": { backgroundColor: "#F2F6FF" },
            },
          },
        ],
        "styleOverrides": {
          "root": {
            "&.Mui-selected": { "background": "#F2F6FF" },
          },
        },
      },
      MuiTab: {
        "styleOverrides": {
          "root": {
            textTransform: "none",
          },
        },
      },
    },
  },
  ruRU,
);
