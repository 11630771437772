import React from "react";
import cl from "classnames";
import { NavLink } from "react-router-dom";

// Scss
import styles from "./MenuLink.module.scss";

// Typescript
import { MenuLinkProps } from "./MenuLink.props";

const MenuLink: React.FC<MenuLinkProps> = ({ name, icon: Icon, ...props }): JSX.Element => {
  return props.to.toString().includes("https") ? (
    <li className={cl(styles["menu-link"])}>
      <a className={cl(styles["menu-link__nav-link"])} href={props.to.toString()} target='_blank' rel='noreferrer'>
        <Icon className={cl(styles["menu-link__icon"])} />
        {name}
        <i className={cl(styles["menu-link__indicator"])} />
      </a>
    </li>
  ) : (
    <li className={cl(styles["menu-link"])}>
      <NavLink
        className={({ isActive }) =>
          cl(styles["menu-link__nav-link"], {
            [styles["menu-link__nav-link_active"]]: isActive,
          })
        }
        target={props.to.toString().includes("https") ? "_blank" : "_self"}
        {...props}
      >
        <Icon className={cl(styles["menu-link__icon"])} />
        {name}
        <i className={cl(styles["menu-link__indicator"])} />
      </NavLink>
    </li>
  );
};

export default React.memo(MenuLink);
