export type TimeUnits = "second" | "minute" | "hour" | "day" | "month" | "year";
export const detectUnitsByTime = (milliseconds: number): TimeUnits => {
  const seconds = (new Date().getTime() - milliseconds) / 1000;

  if (seconds < 60) return "second";
  if (seconds > 60 && seconds < 3600) return "minute";
  if (seconds > 3600 && seconds < 3600 * 24) return "hour";
  if (seconds > 3600 * 24 && seconds < 3600 * 24 * 28) return "day";
  if (seconds > 3600 * 24 * 28 && seconds < 3600 * 24 * 28 * 12) return "month";
  if (seconds > 3600 * 24 * 28 * 12) return "year";
  else return "minute";
};
