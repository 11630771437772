import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { IOrganization } from "types/models";
import { OrganizationCreationFields } from "pages/Public/CreateOrganization/CreateOrganization.props";

class OrganizationAPI extends GlobalAPI {
  async create(organization: OrganizationCreationFields): Promise<IOrganization> {
    return this.axios.post("/organizations", organization).then((res) => res.data);
  }

  async findOrganizationById(organizationId: string | number): Promise<IOrganization> {
    return this.axios.get(`/organizations/${organizationId}`).then((res) => res.data);
  }

  async updateOrganization(
    organizationId: string | number,
    payload: OrganizationCreationFields,
  ): Promise<IOrganization> {
    return this.axios.patch(`/organizations/${organizationId}`, payload).then((res) => res.data);
  }

  async updatePhoto(organizationId: string | number, formData: FormData): Promise<IOrganization> {
    return this.axios.patch(`/organizations/${organizationId}/photo`, formData).then((res) => res.data);
  }
}

export default new OrganizationAPI();
