import * as Yup from "yup";

export const signInSchema = Yup.object()
  .shape({
    email: Yup.string().email("Введите адрес электронной почты").required("Поле обязательно для заполнения"),
    password: Yup.string()
      .min(8, "Пароль не может быть меньше 8 символов")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
        "Пароль может содержать цифры, специальные символы или латинские буквы",
      )
      .required("Поле обязательно для заполнения"),
  })
  .required();

export const signUpSchema = Yup.object()
  .shape({
    email: Yup.string().email("Введите адрес электронной почты").required("Поле обязательно для заполнения"),
    phone: Yup.string()
      .test(
        "len10",
        "Телефон не может быть меньше 10 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length >= 10,
      )
      .test(
        "len13",
        "Телефон не может быть больше 13 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length <= 13,
      )
      .required("Поле обязательно для заполнения"),
    fullName: Yup.string()
      .matches(/(\W+) (\W+)/gi, "Имя и фамилия должны быть обязательно указаны")
      .required("Поле обязательно для заполнения"),
    password: Yup.string()
      .min(8, "Пароль не может быть меньше 8 символов")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
        "Пароль не может быть менее 8 символов и должен включать хотя бы одну цифру и одну букву верхнего регистра",
      )
      .required("Поле обязательно для заполнения"),
    repeatPassword: Yup.string().oneOf([Yup.ref("password"), null], "Пароли должны совпадать"),
  })
  .required();

export const resetSchema = Yup.object()
  .shape({
    email: Yup.string().email("Введите адрес электронной почты").required("Поле обязательно для заполнения"),
  })
  .required();

export const resetConfirmSchema = Yup.object()
  .shape({
    password: Yup.string()
      .min(8, "Пароль не может быть меньше 8 символов")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
        "Пароль не может быть менее 8 символов и должен включать хотя бы одну цифру и одну букву верхнего регистра",
      )
      .required("Поле обязательно для заполнения"),
    repeatPassword: Yup.string().oneOf([Yup.ref("password"), null], "Пароли должны совпадать"),
  })
  .required();

export const changeProfileSchema = Yup.object()
  .shape({
    email: Yup.string().email("Введите адрес электронной почты").required("Поле обязательно для заполнения"),
    phone: Yup.string()
      .test(
        "len10",
        "Телефон не может быть меньше 10 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length >= 10,
      )
      .test(
        "len13",
        "Телефон не может быть больше 13 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length <= 13,
      )
      .required("Поле обязательно для заполнения"),
    fullName: Yup.string()
      .matches(/(\W+) (\W+)/gi, "Имя и фамилия должны быть обязательно указаны")
      .required("Поле обязательно для заполнения"),
  })
  .required();

export const changePasswordSchema = Yup.object()
  .shape({
    currentPassword: Yup.string()
      .min(8, "Пароль не может быть меньше 8 символов")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/, "Пароль может содержать только латинские буквы")
      .required("Поле обязательно для заполнения"),
    newPassword: Yup.string()
      .min(8, "Пароль не может быть меньше 8 символов")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/,
        "Пароль не может быть менее 8 символов и должен включать хотя бы одну цифру и одну букву верхнего регистра",
      )
      .required("Поле обязательно для заполнения"),
  })
  .required();

export const applicationSchema = Yup.object()
  .shape({
    serviceId: Yup.string().required("Поле обязательно для заполнения"),
    description: Yup.string().required("Поле обязательно для заполнения"),
  })
  .required();

export const phoneLogin = Yup.object()
  .shape({
    phone: Yup.string()
      .test(
        "len10",
        "Телефон не может быть меньше 10 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length >= 10,
      )
      .test(
        "len13",
        "Телефон не может быть больше 13 символов",
        (val) => (val || "").replace(/[^0-9]+/g, "").length <= 13,
      )
      .required("Поле обязательно для заполнения"),
  })
  .required();

export const organizationSchema = Yup.object()
  .shape({
    inn: Yup.string()
      .min(9, "Длина строки не должна быть меньше 9 символов")
      .max(12, "Длина строки не должна быть больше 12 символов")
      .matches(/^\d+$/, "Значение должно состоять только из цифр")
      .required("Поле обязательно для заполнения"),
    kpp: Yup.string()
      .min(9, "Длина строки не должна быть меньше 9 символов")
      .max(9, "Длина строки не должна быть больше 9 символов")
      .matches(/^\d+$/, "Значение должно состоять только из цифр")
      .required("Поле обязательно для заполнения"),
    email: Yup.string()
      .email("Формат email не соответствует нужному формату")
      .required("Поле обязательно для заполнения"),
    phone: Yup.string()
      .test("len10", "Телефон не может быть меньше 10 символов", (val) => {
        return (val || "").replace(/[^0-9]+/g, "").length >= 10;
      })
      .test("len13", "Телефон не может быть больше 13 символов", (val) => {
        return (val || "").replace(/[^0-9]+/g, "").length <= 13;
      })
      .required("Поле обязательно для заполнения"),
    shortName: Yup.string().required("Поле обязательно для заполнения"),
    fullName: Yup.string().required("Поле обязательно для заполнения"),
    postcode: Yup.string()
      .matches(/^\d+$/, "Значение должно состоять только из цифр")
      .required("Поле обязательно для заполнения"),
    legalAddress: Yup.string()
      .min(6, "Длина строки не должна быть меньше 6 символов")
      .required("Поле обязательно для заполнения"),
    actualAddress: Yup.string()
      .min(6, "Длина строки не должна быть меньше 6 символов")
      .required("Поле обязательно для заполнения"),
    bik: Yup.string()
      .matches(/^\d+$/, "Значение должно состоять только из цифр")
      .min(9, "Длина строки не должна быть меньше 9 символов")
      .max(9, "Длина строки не должна быть больше 9 символов")
      .required("Поле обязательно для заполнения"),
    accountNumber: Yup.string().test("accountNumber", "Значение должно состоять только из цифр", (val) => {
      return (val ?? "").length === 0 || /^\d+$/.test(val ?? "");
    }),
  })
  .required();
