import React from "react";
import cl from "classnames";

// Scss
import styles from "./Footer.module.scss";

export const Footer: React.FC = (): JSX.Element => {
  return (
    <footer className={cl(styles["footer"])}>
      <div className={cl(styles["footer__content"])}>
        <small className={cl(styles["footer__copyright"])}>{`© ${new Date().getFullYear()} ООО «РМ Солюшн»`}</small>
        <nav className={cl(styles["footer__links"])}>
          <a
            className={cl(styles["footer__link"])}
            href={process.env.REACT_APP_DOCUMENTS_RULES}
            target='_blank'
            rel='noreferrer'
          >
            Правила пользования
          </a>
          <a
            className={cl(styles["footer__link"])}
            href={process.env.REACT_APP_DOCUMENTS_CONFIDENTIAL}
            target='_blank'
            rel='noreferrer'
          >
            Политика конфиденциальности
          </a>
          <a className={cl(styles["footer__link"])} href='https://rmgroup.pro' target='_blank' rel='noreferrer'>
            Контакты
          </a>
        </nav>
      </div>
    </footer>
  );
};
