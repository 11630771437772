import React, { HTMLProps } from "react";
import cl from "classnames";

// Blocks
import { SettingsMenuGroup } from "blocks";

// Scss
import styles from "./SettingsLayout.module.scss";

export const SettingsLayout: React.FC<HTMLProps<HTMLDivElement>> = ({ children }): JSX.Element => {
  return (
    <div className={cl(styles["settings"])}>
      <main className={cl(styles["settings__content"])}>
        <SettingsMenuGroup />
        <div className={cl(styles["content-box"])}>{children}</div>
      </main>
    </div>
  );
};
