import React, { useCallback, useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";

// Material UI
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import { ACLFilterOptions } from "services/Passport/MicroServicesAPI";

// Services
import { MicroServicesAPI } from "services";

// Typescript
import { AccessLevelSearchProps } from "./AccessLevelSearch.props";
import { MicroserviceACL } from "types/models";

export const AccessLevelSearch: React.FC<AccessLevelSearchProps> = ({
  filter,
  selectedACL,
  allowedServices,
  onSuggestionSelect,
}): JSX.Element => {
  const [searchText, setSearchText] = useState("");
  const [isSearching, setSearchingStatus] = useState(false);
  const [aclSuggestions, setACLSuggestion] = useState<MicroserviceACL[]>([]);

  const searchACL = useCallback(async () => {
    if (searchText.length % 2 === 0) {
      setSearchingStatus(true);

      let searchOptions: Partial<ACLFilterOptions> = { search: searchText, type: filter, exclude: selectedACL };

      if (!_isEmpty(allowedServices)) {
        searchOptions = { ...searchOptions, services: allowedServices };
      }

      const { rows } = await MicroServicesAPI.findAllACL(searchOptions);
      setACLSuggestion(rows);

      setSearchingStatus(false);
    }
  }, [allowedServices, filter, searchText, selectedACL]);

  useEffect(() => {
    searchACL();
  }, [searchACL]);

  const handleSuggestionsFieldChange = (_: React.SyntheticEvent, value: string) => {
    setSearchText(value);
  };

  const handleSuggestionClick = (_: React.SyntheticEvent, value: MicroserviceACL | null) => {
    if (value) {
      onSuggestionSelect(value);
      setSearchText("");
    }
  };

  return (
    <Autocomplete
      loadingText='Идет поиск...'
      noOptionsText='Ничего не найдено'
      value={null}
      loading={isSearching}
      inputValue={searchText}
      options={aclSuggestions}
      getOptionLabel={(option) => option.id}
      onInputChange={handleSuggestionsFieldChange}
      onChange={handleSuggestionClick}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Поиск прав доступа'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isSearching ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
