import { Dispatch } from "@reduxjs/toolkit";

// Services
import { UsersAPI } from "services/Passport";

// Slices
import { userSlice } from "store/reducers/UserSlice";

export const fetchUserData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    const userData = await UsersAPI.findMe().catch(() => {
      dispatch(userSlice.actions.handleLoginError());
    });

    if (!userData) return;

    dispatch(userSlice.actions.setCurrentUser(userData));
  };
