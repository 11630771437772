import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Scss
import styles from "./Invitation.module.scss";

// Typescript
import { InvitationProps } from "./Invitation.props";

const Invitation: React.FC<InvitationProps> = ({
  id,
  title,
  subtitle,
  acceptButtonText,
  declineButtonText,
  className,
  onAccept,
  onDecline,
  ...props
}): JSX.Element => {
  const handleInvitationAcceptClick = () => {
    onAccept(id);
  };

  const handleInvitationDeclineClick = () => {
    onDecline(id);
  };

  return (
    <li className={cl(styles["invitation"], className)} {...props}>
      <div className={cl(styles["invitation__text"])}>
        <h2 className={cl(styles["invitation__title"])}>{title}</h2>
        <span className={cl(styles["invitation__subtitle"])}>{subtitle}</span>
      </div>
      <Button
        size='large'
        type='button'
        variant='contained'
        onClick={handleInvitationAcceptClick}
        sx={{ width: "max-content" }}
      >
        {acceptButtonText}
      </Button>
      <Button
        size='large'
        type='button'
        variant='outlined'
        onClick={handleInvitationDeclineClick}
        sx={{ width: "max-content" }}
      >
        {declineButtonText}
      </Button>
    </li>
  );
};

export default React.memo(Invitation);
