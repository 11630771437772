import React from "react";
import { useNavigate } from "react-router";

// Redux
import { useAppSelector } from "hooks/redux";
import { StatusPage } from "pages";
import { checkAccessLevel } from "utils/ACL";

interface AdminRouteProps {
  acl: string[];
  element: JSX.Element;
}

export const AdminRoute: React.FC<AdminRouteProps> = ({ acl, element }): JSX.Element => {
  const navigate = useNavigate();

  // Redux
  const { user } = useAppSelector((state) => state.userReducer);

  const handleBackClick = () => navigate("/home");

  const hasAccess = checkAccessLevel(
    (user?.acl || []).map((perm) => perm.microserviceACLId),
    ...acl,
  );

  return hasAccess ? (
    element
  ) : (
    <StatusPage
      onClick={handleBackClick}
      buttonText='На главную'
      status='error'
      title='У Вас не доступа к данному ресурсу'
    />
  );
};
