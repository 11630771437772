import axios from "axios";
import Cookies from "js-cookie";
import addDays from "date-fns/addDays";

// Services
import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { ISession } from "types/models";
import { MultiResponse } from "types/MultiResponse";

class SessionsAPI extends GlobalAPI {
  async login(loginData: Record<string, string>) {
    const fp = await this.fingerPrintJS;
    const fingerprint = await fp.get();

    Cookies.set("rms_fingerprint", fingerprint.visitorId, {
      secure: true,
      expires: addDays(new Date(), 3),
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    const headers = { ...this.staticHeaders, "x-user-fingerprint": fingerprint?.visitorId };

    return axios
      .post(
        `${process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1"}/sessions/users/sign-in/password`,
        loginData,
        { withCredentials: true, headers: headers },
      )
      .then((res) => res.data);
  }

  async requestPhoneLogin(phone: string) {
    const fp = await this.fingerPrintJS;
    const fingerprint = await fp.get();

    Cookies.set("rms_fingerprint", fingerprint.visitorId, {
      secure: true,
      expires: addDays(new Date(), 3),
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    return axios
      .get(`${process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1"}/sessions/users/sign-in/phone`, {
        params: { phone },
        headers: { ...this.staticHeaders, "x-user-fingerprint": fingerprint?.visitorId },
      })
      .then((res) => res.data);
  }

  async loginByCode(code: string, phone: string) {
    const fp = await this.fingerPrintJS;
    const fingerprint = await fp.get();

    Cookies.set("rms_fingerprint", fingerprint.visitorId, {
      secure: true,
      expires: addDays(new Date(), 3),
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

    return axios
      .post(
        `${process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1"}/sessions/users/sign-in/phone`,
        { code, phone },
        { withCredentials: true, headers: { ...this.staticHeaders, "x-user-fingerprint": fingerprint?.visitorId } },
      )
      .then((res) => res.data)
      .catch(this._handleError);
  }

  async findAll(page: number, amount = 10): Promise<MultiResponse<ISession>> {
    return this.axios.get("/sessions/users/me", { params: { page, amount } }).then((res) => res.data);
  }

  async removeOne(sessionId: number): Promise<MultiResponse<ISession>> {
    return this.axios.delete(`/sessions/users/${sessionId}`).then((res) => res.data);
  }

  async findCurrent(): Promise<ISession> {
    return this.axios.get("/sessions/users/current").then((res) => res.data);
  }

  async logout() {
    return this.axios.delete("/sessions/users/current").then((res) => res.data);
  }

  async logoutAll() {
    return this.axios.delete("/sessions/users/me").then((res) => res.data);
  }
}

export default new SessionsAPI();
