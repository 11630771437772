import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Layouts
import { PopupLayout } from "layouts";
// Components
import { Loader } from "components/Elements";

// Styles
import styles from "./PhotoUpload.module.scss";

// Typescript
import { PhotoUploadProps } from "./PhotoUpload.props";

// Utils
import { getCroppedImg } from "./Crop";

export const PhotoUpload: React.FC<PhotoUploadProps> = ({
  photoURL,
  isOpen,
  onSuccessPhotoCrop,
  onClose = () => {},
}): JSX.Element => {
  const [isPending, setPendingStatus] = useState(true);
  const [orientation, setOrientation] = useState<"vertical" | "horizontal" | null>(null);

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);

  const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handlePhotoSaveClick = async () => {
    if (croppedArea) {
      const croppedImage = await getCroppedImg(photoURL, croppedArea);
      if (croppedImage) {
        await onSuccessPhotoCrop(croppedImage).then(() => onClose());
      }
    }
  };

  useEffect(() => {
    setPendingStatus(true);

    const image = new Image();
    image.src = photoURL;

    image.addEventListener("load", () => {
      setPendingStatus(false);
      setOrientation(image.naturalHeight > image.naturalWidth ? "vertical" : "horizontal");
    });
  }, [photoURL]);

  return (
    <PopupLayout isOpen={isOpen} title='Загрузка фотографии' onClose={onClose}>
      <div className={cl(styles["popup__content"])}>
        <Loader isActive={isPending} />
        <div className={cl(styles["photo-area"], styles[`photo-area_${orientation}`])}>
          <div className={cl(styles["photo-area__container"])}>
            {orientation !== null && (
              <Cropper
                image={photoURL}
                crop={crop}
                zoom={zoom}
                aspect={3 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
        </div>
        <div className={cl(styles["buttons"])}>
          <Button fullWidth size='large' type='button' variant='contained' onClick={handlePhotoSaveClick}>
            Сохранить
          </Button>
          <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
            Отменить
          </Button>
        </div>
      </div>
    </PopupLayout>
  );
};
