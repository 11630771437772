import React from "react";

// Material UI
import Tooltip from "@mui/material/Tooltip";

// Images
import { DoneOutlinedIcon, HourglassEmptyOutlinedIcon } from "assets/icons";

// Typescript
import { Column, GenericTableProps } from "blocks";
import { IOrganizationEmployee, IUser } from "types/models";
import { parseStringToPhone } from "utils/Phone";

export interface EmployeesTableProps extends GenericTableProps {
  employees: IOrganizationEmployee[];
}

export const employeesTableColumns: Column[] = [
  { id: "id", label: "ID", align: "center" },
  {
    id: "user",
    label: "Фамилия и имя",
    format: (value) => {
      return `${(value as IUser).surname} ${(value as IUser).name}`;
    },
  },
  { id: "user.email", label: "E-mail" },
  {
    id: "user.phone",
    label: "Телефон",
    format: (value) => {
      return parseStringToPhone(value as string).formattedNumber;
    },
  },
  {
    id: "confirmed",
    label: "Статус",
    align: "center",
    style: { display: "flex", alignItems: "center", justifyContent: "center" },
    format: (value) => {
      return (
        <Tooltip
          title={
            (value as boolean)
              ? "Пользователь принял приглашение в организацию"
              : "Пользователь ещё не принял приглашение в организацию"
          }
        >
          {(value as boolean) ? <DoneOutlinedIcon color='success' /> : <HourglassEmptyOutlinedIcon color='disabled' />}
        </Tooltip>
      );
    },
  },
];
