import React, { useCallback, useMemo } from "react";
import cl from "classnames";

// Material UI
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";

// Scss
import styles from "./BaseModal.module.scss";

// Typescript
import { BaseModalProps } from "./BaseModal.props";

export const BaseModal: React.FC<BaseModalProps> = ({ buttons, isOpen, onClose, ...props }): JSX.Element => {
  const handleButtonClick = useCallback(
    (cb: Function) => (evt: React.MouseEvent<HTMLElement>) => {
      if (onClose) onClose(evt, "backdropClick");
      cb(evt);
    },
    [onClose],
  );

  const Buttons = useMemo(
    () =>
      buttons.map(({ key, text, icon: Icon, onClick }) => (
        <li key={key} onClick={handleButtonClick(onClick)} className={cl(styles["modal__button"])}>
          {Icon != null && <Icon className={cl(styles["modal__icon"])} />}
          <span className={cl(styles["modal__text"])}>{text}</span>
        </li>
      )),
    [buttons, handleButtonClick],
  );

  return (
    <Popover
      id='modal'
      open={isOpen ?? false}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={Fade}
      onClose={onClose}
      {...props}
    >
      <ul className={cl(styles["modal__buttons"])}>{Buttons}</ul>
    </Popover>
  );
};
