import React, { useCallback, useEffect, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";
import _ from "lodash";

// Material UI
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Services
import { OrganizationsAPI } from "services";

// Images
import { EditOutlinedIcon } from "assets/icons";
// Layouts
import { ContentBox } from "layouts";
// Components
import { UploadPhoto } from "components/Inputs";
import { Loader, ParameterView } from "components/Elements";
// Popups
import { OrganizationFormPopup } from "popups";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./Organization.module.scss";

// Typescript
import { IOrganization } from "types/models";

// Utils
import { parseStringToPhone } from "utils/Phone";
import { checkOrganizationAccess } from "utils/ACL";

export const Organization: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();

  const { user } = useAppSelector((state) => state.userReducer);
  const [isPending, setPendingStatus] = useState(true);

  const [organization, setOrganization] = useState<IOrganization | null>(null);

  const loadOrganizationData = useCallback(async () => {
    setPendingStatus(true);

    const dbOrganization = await OrganizationsAPI.findOrganizationById(user?.organizations[0].id ?? "");
    setOrganization(dbOrganization);

    setTimeout(() => setPendingStatus(false), 200);
  }, [user?.organizations]);

  useEffect(() => {
    loadOrganizationData();
  }, [loadOrganizationData]);

  // Колбэк функция, срабатывающая после успешного обрезания фотографии профиля
  // Выполняет сохранение фотографии на сервере и делает перезагрузку страницы
  const onSuccessPhotoSave = async (croppedFile: Blob) => {
    setPendingStatus(true);
    const formData = new FormData();
    formData.append("photo", croppedFile);

    return OrganizationsAPI.updatePhoto(organization?.id + "", formData).then(async () => {
      enqueueSnackbar("Фотография профиля успешно обновлена", { variant: "success" });
      loadOrganizationData();
    });
  };

  const onSuccessOrganizationUpdate = () => {
    enqueueSnackbar("Указанные данные успешно сохранены", { variant: "success" });
    loadOrganizationData();
  };

  // Колбэк функция при отправке формы
  const handleOrganizationEditClick = () => {
    if (organization) {
      popupManager.open(OrganizationFormPopup, {
        organizationId: organization.id,
        defaultValues: {
          shortName: organization.details.shortName,
          fullName: organization.details.fullName,
          inn: organization.details.inn,
          kpp: organization.details.kpp,
          bik: organization.details.bik,
          accountNumber: organization.details.accountNumber,
          actualAddress: organization.details.actualAddress,
          legalAddress: organization.details.legalAddress,
          email: organization.details.email,
          phone: organization.details.phone,
          postcode: organization.details.postcode,
          eula: true,
        },
        onSuccessResponse: onSuccessOrganizationUpdate,
      });
    }
  };

  return (
    <>
      <Loader isActive={isPending} />
      <ContentBox expanded>
        <div className={cl(styles["organization"])}>
          <UploadPhoto
            imageUrl={organization?.photo ?? ""}
            disabled={!checkOrganizationAccess("organization.control.editor")}
            onSuccessPhotoSave={onSuccessPhotoSave}
          />

          <div className={cl(styles["organization__info"])}>
            <div className={cl(styles["organization__info-box"])}>
              <div className={cl(styles["organization__info-row"])}>
                <h2 className={cl(styles["organization__name"])}>{organization?.details.shortName}</h2>
                {checkOrganizationAccess("organization.admin") && (
                  <Tooltip title='Редактировать информацию'>
                    <IconButton type='button' onClick={handleOrganizationEditClick}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <span className={cl(styles["organization__id"])}>ID: {organization?.id}</span>
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <ParameterView
                  title='Телефон'
                  value={parseStringToPhone(organization?.details.phone ?? "").formattedNumber}
                />
                <ParameterView title='E-mail' value={organization?.details.email ?? ""} />
                <ParameterView title='Краткое название организации' value={organization?.details.shortName ?? ""} />
              </div>
            </div>
            <div className={cl(styles["organization__info-box"])}>
              <div className={cl(styles["organization__info-row"])}>
                <h2 className={cl(styles["organization__name"])}>Данные организации</h2>
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <ParameterView title='Полное название организации' value={organization?.details.fullName ?? ""} />
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <ParameterView title='ИНН' value={organization?.details.inn ?? ""} />
                <ParameterView title='КПП' value={organization?.details.kpp ?? ""} />
                <ParameterView title='БИК' value={organization?.details.bik ?? ""} />
                <ParameterView
                  title='Расчетный счет'
                  value={
                    _.isEmpty(organization?.details.accountNumber)
                      ? "Не заполнено"
                      : organization?.details.accountNumber ?? ""
                  }
                />
                <ParameterView title='Почтовый индекс' value={organization?.details.postcode ?? ""} />
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <ParameterView title='Юридический адрес' value={organization?.details.legalAddress ?? ""} />
              </div>
              <div className={cl(styles["organization__info-row"])}>
                <ParameterView title='Фактический адрес' value={organization?.details.actualAddress ?? ""} />
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </>
  );
};
