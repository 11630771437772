import React from "react";
import cl from "classnames";

// Scss
import styles from "./ParameterView.module.scss";

// Typescript
import { ParameterViewProps } from "./ParameterView.props";

const ParameterView: React.FC<ParameterViewProps> = ({ title, value, className, ...props }): JSX.Element => {
  return (
    <li className={cl(styles["parameter-view"], className)} {...props}>
      <span className={cl(styles["parameter-view__title"])}>{title}</span>
      <h4 className={cl(styles["parameter-view__value"])}>{value}</h4>
    </li>
  );
};

export default React.memo(ParameterView);
