import React, { useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { addMinutes } from "date-fns";
import { Helmet } from "react-helmet";
import { AxiosError } from "axios";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// Services
import { SessionsAPI } from "services";

// Components
import { DigitCodeField } from "components/Inputs";
import { CountdownButton } from "components/Buttons";

// Scss
import styles from "./PhoneLogin.module.scss";

// Redux
import { useAppDispatch } from "hooks/redux";
import { userSlice } from "store/reducers/UserSlice";
import { fetchUserData } from "store/actions/UserActions";

// Utils
import { parseStringToPhone } from "utils/Phone";
import { phoneLogin } from "utils/Schemas";

export const PhoneLogin: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Текущая стадия, на которой открыта форма
  const [stage, setStage] = useState<"phone" | "code">("phone");
  // 4-ех значный код, введенный пользователем
  const [digitCode, setDigitCode] = useState("");
  // Статус отправки кода на сервер
  const [isDigitCodeSubmitting, setDigitCodeSubmitting] = useState(false);
  // Дата, когда будет доступна отправка следующего запроса на звонок
  const [nextCallAttempt, setNextCallAttempt] = useState<Date>(new Date());
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm<{ phone: string }>({ mode: "all", resolver: yupResolver(phoneLogin) });
  const watchedValues = useWatch({ control });

  // Обработчик ошибки сервера
  const onServerRequestError = (error: AxiosError) => {
    const message = error.response?.data.message;

    if (error.response?.status === 409) {
      setError("phone", { message, type: "block" });
      return;
    }

    if (error.response?.status === 400) {
      setError("phone", { message, type: "value" });
      return;
    }

    enqueueSnackbar(message ?? "Неизвестная ошибка сервера", { variant: "error" });
  };

  // Слушатель отправки формы для получения звонка на номер телефона
  const onFormSubmit: SubmitHandler<{ phone: string }> = async ({ phone }) => {
    setSubmitting(true);

    return SessionsAPI.requestPhoneLogin(phone)
      .then(() => {
        setNextCallAttempt(addMinutes(new Date(), 2));
        setStage("code");
      })
      .catch(onServerRequestError)
      .finally(() => setSubmitting(false));
  };

  const handleDigitCodeSubmit = async () => {
    setDigitCodeSubmitting(true);

    return SessionsAPI.loginByCode(digitCode, watchedValues.phone ?? "")
      .then(({ authToken }) => {
        dispatch(userSlice.actions.setAuthToken(authToken));
        dispatch(fetchUserData());
      })
      .finally(() => setDigitCodeSubmitting(false));
  };

  // Слушатель изменения значения в поле ввода кода
  const handleDigitCodeChange = (code: string) => {
    setDigitCode(code);
  };

  const goToLoginPage = () => navigate("/sign-in");

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Вход</title>
        <meta name='description' content='Страница входа в личный кабинет РМ Солюшн'></meta>
      </Helmet>

      <form onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["content"])}>
        <h1 className={cl(styles["content__title"])}>Вход</h1>
        <p className={cl(styles["content__subtitle"])}>
          После отправки запроса на указанный номер телефона поступит звонок. Введите последние четыре цифры входящего
          номера телефона.
        </p>

        {stage === "phone" ? (
          <TextField
            required
            fullWidth
            label='Телефон'
            placeholder='+7 (___) ___-__-__'
            {...register("phone")}
            value={parseStringToPhone(watchedValues.phone ?? "").formattedNumber}
            error={!!errors["phone"]}
            helperText={errors["phone"]?.message}
            sx={{ marginTop: "24px" }}
          />
        ) : (
          <DigitCodeField
            codeLength={4}
            value={digitCode}
            onChange={handleDigitCodeChange}
            className={cl(styles["content__field"])}
          />
        )}

        <div className={cl(styles["content__buttons"])}>
          {stage === "phone" ? (
            <>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Запросить звонок
              </LoadingButton>
              <Button size='large' type='button' variant='outlined' onClick={goToLoginPage}>
                Вернуться назад
              </Button>
            </>
          ) : (
            <>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isDigitCodeSubmitting}
                disabled={digitCode.length !== 4 || isDigitCodeSubmitting}
                onClick={handleDigitCodeSubmit}
              >
                Войти
              </LoadingButton>
              <CountdownButton
                size='large'
                type='button'
                variant='outlined'
                date={nextCallAttempt}
                countDownTemplate='m:ss'
              >
                Позвонить еще раз
              </CountdownButton>
            </>
          )}
        </div>
      </form>
    </>
  );
};
