import React, { useCallback, useEffect, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Images
import { LogoutOutlinedIcon } from "assets/icons";
// Layouts
import { ContentBox } from "layouts";
// Components
import { Loader } from "components/Elements";
// Popups
import { PasswordChange } from "popups";
// Blocks
import { SessionsTable } from "blocks";

// Redux
import { useAppSelector } from "hooks/redux";

// Services
import { SessionsAPI } from "services/Passport";

// Scss
import styles from "./Security.module.scss";

// Typescript
import { ISession } from "types/models";

export const Security: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();

  const { user } = useAppSelector((state) => state.userReducer);

  // Индикатор загрузки данных
  const [isPending, setPendingStatus] = useState(true);

  // Сессия текущего устройства
  const [currentSession, setCurrentSession] = useState<ISession | null>(null);
  // Массив всех сессий аккаунта
  const [sessions, setSessions] = useState<ISession[]>([]);
  // Максимальная страница пагинации
  const [maxCount, setMaxCount] = useState(0);

  const loadCurrentSession = async () => {
    const session = await SessionsAPI.findCurrent();
    setCurrentSession(session);
  };

  useEffect(() => {
    loadCurrentSession();
  }, []);

  const loadSessions = useCallback(
    async (page: number) => {
      setPendingStatus(true);

      const dbSessions = await SessionsAPI.findAll(page + 1);

      setSessions(dbSessions.rows.filter((i) => i.id !== currentSession?.id));
      setMaxCount(dbSessions.count);

      setTimeout(() => setPendingStatus(false), 200);
    },
    [currentSession],
  );

  // Слушатель клика по кнопке "Изменить пароль"
  const handlePasswordChangePopupOpen = () => {
    if (user) {
      popupManager.open(PasswordChange, {
        user: user,
        onSuccessServerResponse: async () => {
          enqueueSnackbar("Пароль от аккаунта изменен", { variant: "success" });
          window.location.reload();
        },
      });
    }
  };

  // Слушатель нажатия на кнопку "Выйти на других устройствах"
  const closeAllSessions = async () => {
    await SessionsAPI.logoutAll().then(async () => {
      enqueueSnackbar("Все сессии кроме текущей успешно удалены", { variant: "success" });
    });
  };

  return (
    <>
      <Loader isActive={isPending} />
      <div className={cl(styles["security"])}>
        <div className={cl(styles["password-box"])}>
          <div className={cl(styles["password-box__content"])}>
            <h2 className={cl(styles["password-box__title"])}>Управление паролем</h2>
            <div className={cl(styles["password-box__info"])}>
              <span className={cl(styles["password-box__role"])}>
                {user?.isAdmin ? "Администратор" : "Пользователь"}
              </span>
              <span className={cl(styles["password-box__id"])}>ID: {user?.id}</span>
            </div>
          </div>
          <Button size='large' variant='outlined' onClick={handlePasswordChangePopupOpen} sx={{ width: "204px" }}>
            Сменить пароль
          </Button>
        </div>

        <ContentBox expanded title='Входы и устройства'>
          <h3 className={cl(styles["content__subtitle"])}>Это устройство</h3>

          {currentSession && (
            <SessionsTable
              maxCount={maxCount}
              sessions={sessions}
              currentSession={currentSession}
              onTableChange={loadSessions}
            />
          )}

          {sessions.length === 0 && (
            <div className={cl(styles["sessions__empty"])}>
              <span className={cl(styles["sessions__empty-text"])}>Других сессий не найдено</span>
            </div>
          )}

          {sessions.length > 0 && (
            <Button
              type='button'
              variant='text'
              startIcon={<LogoutOutlinedIcon />}
              onClick={closeAllSessions}
              className={cl(styles["sessions__logout"])}
            >
              Выйти на других устройствах
            </Button>
          )}
        </ContentBox>
      </div>
    </>
  );
};
