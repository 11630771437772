import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import _get from "lodash/get";

// Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

// Services
import { UsersAPI } from "services";

// Images
import {
  FormatListBulletedOutlinedIcon,
  MoreVertOutlinedIcon,
  PersonOffOutlinedIcon,
  PersonOutlineOutlinedIcon,
} from "assets/icons";
// Components
import { BaseModal, BaseModalButton } from "components/Modals";
// Popups
import { UserAccessInstance, UserAccessPopup } from "popups";

// Typescript
import { UsersTableProps, usersTableColumns } from "./UsersTable.props";
import { IUser } from "types/models";

// Utils
import { checkGlobalAccess } from "utils/ACL";

export const UsersTable: React.FC<UsersTableProps> = ({ maxCount, users, onTableChange }): JSX.Element => {
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);

  const handlePageChange = (evt: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onTableChange(currentPage);
  }, [currentPage, onTableChange]);

  const handleUserStatusChange = useCallback(
    (userId: number | string, isBlocked: boolean) => {
      const reason = "Для уточнения информации обращаться по почте help@rmgroup.pro";

      UsersAPI.changeStatus(userId, isBlocked ? "unblock" : "block", { reason })
        .then(async (user) => {
          enqueueSnackbar(`Пользователь "${user.email}" ${isBlocked ? " разблокирован" : "заблокирован"}`, {
            variant: "success",
          });
          await onTableChange(currentPage);
        })
        .finally(() => setSelectedRowsIds([]));
    },
    [currentPage, enqueueSnackbar, onTableChange],
  );

  const onUserACLUpdateFormSubmit = useCallback(
    async (instance: UserAccessInstance, selectedACL: string[]) => {
      return UsersAPI.updateUserACL(instance.id, selectedACL)
        .then(async () => {
          enqueueSnackbar(`Права доступа пользователя (${instance.email}) успешно обновлены`, { variant: "success" });
          await onTableChange(currentPage);
        })
        .finally(() => setSelectedRowsIds([]));
    },
    [currentPage, enqueueSnackbar, onTableChange],
  );

  const handleDotsClick = useCallback(
    (user: IUser) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSelectedRowsIds([user.id]);

      const target = evt.target as HTMLElement;
      const anchorEl = target.closest("svg") as SVGSVGElement;

      const buttons: BaseModalButton[] = [];

      if (checkGlobalAccess("passport.users.editor")) {
        buttons.push({
          key: "1",
          icon: FormatListBulletedOutlinedIcon,
          text: "Изменить права доступа",
          onClick: () => {
            popupManager.open(UserAccessPopup, {
              title: "Настройки прав доступа пользователя",
              instance: { id: user.id, email: user.email, name: `${user.surname} ${user.name}` },
              onlyPublicACL: false,
              initialACL: user.acl.map((acl) => acl.microserviceACLId),
              onFormSubmit: onUserACLUpdateFormSubmit,
            });
          },
        });
      }

      if (checkGlobalAccess("passport.users.admin")) {
        buttons.push({
          key: "2",
          icon: user.isBlocked ? PersonOutlineOutlinedIcon : PersonOffOutlinedIcon,
          text: user.isBlocked ? "Разблокировать" : "Заблокировать",
          onClick: () => handleUserStatusChange(user.id, user.isBlocked),
        });
      }

      popupManager.open(BaseModal, { key: "sessions-modal", anchorEl, buttons, onClose: () => setSelectedRowsIds([]) });
    },
    [handleUserStatusChange, onUserACLUpdateFormSubmit, popupManager],
  );

  const UsersElementsRows = useMemo(
    () =>
      users.map((user) => (
        <TableRow color='hover' selected={selectedRowsIds.includes(user.id)} key={user.id}>
          {usersTableColumns.map(({ id, format, ...props }, idx) => {
            const value = id === "self" ? user : _get(user, id);
            return (
              <TableCell key={id + idx} {...props}>
                {format ? format(value) : value}
              </TableCell>
            );
          })}
          <TableCell align='center' style={{ width: 48 }}>
            <IconButton
              disableFocusRipple
              type='button'
              disableRipple
              onClick={handleDotsClick(user)}
              sx={{ padding: 0 }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [handleDotsClick, selectedRowsIds, users],
  );

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {usersTableColumns.map(({ id, label, format, ...props }, idx) => (
                <TableCell key={id + idx} {...props}>
                  {format ? label : label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{UsersElementsRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={maxCount}
        rowsPerPage={10}
        page={currentPage}
        rowsPerPageOptions={[10]}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to, count }) => {
          return "Сессии " + from + "-" + to + " из " + count;
        }}
      />
    </>
  );
};
