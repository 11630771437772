import React, { useRef } from "react";
import { usePopupManager } from "react-popup-manager";
import cl from "classnames";

// Popups
import { PhotoUpload } from "popups";
// Images
import { ReactComponent as UploadPhotoSVG } from "assets/icons/upload-photo.svg";

// Scss
import styles from "./UploadPhoto.module.scss";

// Typescript
import { UploadPhotoProps } from "./UploadPhoto.props";

const UploadPhoto: React.FC<UploadPhotoProps> = ({ disabled, imageUrl, onSuccessPhotoSave }): JSX.Element => {
  const popupManager = usePopupManager();
  const photoInputRef = useRef<HTMLInputElement>(null);

  // Слушатель ошибки загрузки фотографии профиля
  const handleImageLoadError = (evt: React.SyntheticEvent<HTMLImageElement>) => {
    const target = evt.target as HTMLImageElement;
    target.src = "https://catalogue.storage.yandexcloud.net/default/2021-10-07/d9ab286a19806baf6fda9f8849d25994.webp";
  };

  // Слушатель изменения файла (его загрузка) в полее для загрузки фото профиля
  const handlePhotoFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      const file = evt.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.readAsDataURL(file);
      }

      // Сделано для фикса заморозки страницы после выбора фотографии на компьютере
      reader.addEventListener("load", () => {
        popupManager.open(PhotoUpload, {
          photoURL: URL.createObjectURL(file),
          onSuccessPhotoCrop: onSuccessPhotoSave,
        });
      });
    }
  };

  // Слушатель клика по фотографии профиля (открывает окно выбора фотографии)
  const handleUploadImageClick = () => {
    if (photoInputRef.current) photoInputRef.current.click();
  };

  return (
    <div className={cl(styles["upload-photo"], { [styles["upload-photo_disabled"]]: disabled })}>
      <img
        onError={handleImageLoadError}
        src={imageUrl ?? ""}
        alt='Фотография профиля'
        className={cl(styles["upload-photo__preview"])}
      />

      {!disabled && (
        <>
          <div onClick={handleUploadImageClick} className={cl(styles["upload-photo__wrapper"])}>
            <UploadPhotoSVG className={cl(styles["upload-photo__wrapper-icon"])} />
          </div>
          <input
            type='file'
            accept='image/png, image/webp, image/jpeg'
            className={cl(styles["upload-photo__input"])}
            onChange={handlePhotoFileChange}
            ref={photoInputRef}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(UploadPhoto);
