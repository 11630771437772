import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { AxiosError } from "axios";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

// Services
import { DaDataAPI } from "services/External";
import { UsersAPI } from "services/Passport";

// Pages
import { StatusPage } from "pages";
// Components
import { PasswordInput } from "components/Inputs";

// Schemas
import { signUpSchema } from "utils/Schemas";

// utils
import { parseStringToPhone } from "utils/Phone";

// Scss
import styles from "./Register.module.scss";

// Typescript
import { RegisterFields } from "./Register.props";

export const Register: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm<RegisterFields>({ mode: "all", resolver: yupResolver(signUpSchema) });
  const { fullName, phone, confidential, policy } = useWatch({ control });
  const [isSubmitting, setSubmitting] = useState(false);

  // Индикатор того, что пользователь зарегистрировался
  const [isRegistrationCompleted, setRegistrationCompleteStatus] = useState(false);
  const [isSuggestionsPending, setSuggestionsStatus] = useState(false);
  // Массив с подсказками ФИО
  const [fullNameSuggestions, setFullNameSuggestions] = useState<string[]>([]);

  const onRegisterRequestError = (error: AxiosError) => {
    const message = error.response?.data.message;

    if (error.response?.status === 400) {
      const errorField = error.response?.data.field as keyof RegisterFields;
      setError(errorField, { message: message, type: "validate" });
    } else {
      enqueueSnackbar(message ?? "Неизвестная ошибка сервера", { variant: "error" });
    }
  };

  // Слушатель отправки формы
  const onFormSubmit: SubmitHandler<RegisterFields> = async (values) => {
    const nameData = await DaDataAPI.autoCompleteFullName(values.fullName ?? "");
    const result = nameData.suggestions[0];
    const separatedFullName: string[] = (values.fullName ?? "").split(" ");

    const name = result ? result.data.name : separatedFullName[1];
    const surname = result ? result.data.surname : separatedFullName[0];
    const patronymic = result ? result.data.patronymic : separatedFullName.splice(2).join(" ");

    setSubmitting(true);

    await UsersAPI.create({ ...values, name, surname, patronymic })
      .then(() => setRegistrationCompleteStatus(true))
      .catch(onRegisterRequestError)
      .finally(() => setSubmitting(false));
  };

  // Функция загрузки информации о введенных данных ФИО
  // (для поиска вариантов автозаполнения)
  const loadFullNameSuggestions = useCallback(async () => {
    setSuggestionsStatus(true);

    if ((fullName ?? "").length % 2 === 0) {
      const { suggestions } = await DaDataAPI.autoCompleteFullName(fullName ?? "");
      setFullNameSuggestions(suggestions.map((suggestion) => suggestion.value));
    }

    setSuggestionsStatus(false);
  }, [fullName]);

  useEffect(() => {
    loadFullNameSuggestions();
  }, [loadFullNameSuggestions]);

  // Функция для возврата на страницу авторизации
  const goToLoginPage = () => {
    navigate("/sign-in");
  };

  const handleFullNameFieldChange = (_: React.SyntheticEvent, value: string | null) => {
    setValue("fullName", value);
  };

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Регистрация</title>
        <meta name='description' content='Страница регистрации в личном кабинете РМ Солюшн'></meta>
      </Helmet>

      {isRegistrationCompleted ? (
        <StatusPage
          status='success'
          title='Подтверждение регистрации'
          subtitle='Ваш аккаунт успешно зарегистрирован, а на указанную электронную почту отправлено письмо с подтверждением регистрации'
          buttonText='На главную'
          onClick={goToLoginPage}
        />
      ) : (
        <main className={cl(styles["content"])}>
          <h1 className={cl(styles["content__title"])}>Регистрация</h1>
          <form autoComplete='off' onSubmit={handleSubmit(onFormSubmit)} id='register' className={cl(styles["form"])}>
            <TextField
              required
              fullWidth
              label='E-mail'
              type='email'
              {...register("email")}
              error={!!errors["email"]}
              helperText={errors["email"]?.message}
            />
            <TextField
              required
              fullWidth
              label='Телефон'
              placeholder='+7 (___) ___-__-__'
              {...register("phone", { max: 15 })}
              value={parseStringToPhone(phone ?? "").formattedNumber}
              error={!!errors["phone"]}
              helperText={errors["phone"]?.message}
            />
            <Autocomplete
              required
              title='ФИО'
              value={fullName}
              loadingText='Идет поиск'
              options={fullNameSuggestions}
              loading={isSuggestionsPending}
              className={cl(styles["form__suggestions-field"])}
              {...register("fullName")}
              onChange={handleFullNameFieldChange}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  {...register("fullName")}
                  label='ФИО'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isSuggestionsPending ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <PasswordInput
              required
              label='Пароль'
              variant='outlined'
              {...register("password")}
              error={!!errors["password"]}
              helperText={errors["password"]?.message}
            />
            <PasswordInput
              required
              variant='outlined'
              label='Повторите пароль'
              {...register("repeatPassword")}
              error={!!errors["repeatPassword"]}
              helperText={errors["repeatPassword"]?.message}
            />
            <div className={cl(styles["form__checkboxes"])}>
              <FormControlLabel
                control={<Checkbox size='small' {...register("policy")} />}
                label={
                  <>
                    Я принимаю условия{" "}
                    <a target='_blank' href='#'>
                      Пользовательского соглашения
                    </a>
                  </>
                }
              />
              <FormControlLabel
                control={<Checkbox size='small' {...register("confidential")} sx={{ marginTop: "-24px" }} />}
                label={
                  <>
                    Я даю согласие на обработку моих персональных данных на условиях, которые определенны{" "}
                    <a target='_black' href='#'>
                      Политикой конфиденциальности
                    </a>
                  </>
                }
              />
            </div>
            <div className={cl(styles["form__buttons"])}>
              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled={isSubmitting || !policy || !confidential}
              >
                Зарегистрироваться
              </LoadingButton>
              <Button fullWidth size='large' variant='outlined' type='button' onClick={goToLoginPage}>
                Войти
              </Button>
            </div>
          </form>
        </main>
      )}
    </>
  );
};
