import React, { useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

// Services
import { OrganizationsAPI } from "services";

// Layouts
import { PopupLayout } from "layouts";

// Scss
import styles from "./OrganizationFormPopup.module.scss";

// Typescript
import { OrganizationFormPopupProps } from "./OrganizationFormPopup.props";
import { OrganizationCreationFields } from "pages/Public/CreateOrganization/CreateOrganization.props";

// Utils
import { parseStringToPhone } from "utils/Phone";
import { organizationSchema } from "utils/Schemas";

export const OrganizationFormPopup: React.FC<OrganizationFormPopupProps> = ({
  isOpen,
  defaultValues,
  organizationId,
  onSuccessResponse,
  onClose = () => {},
}): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<OrganizationCreationFields>({
    mode: "all",
    resolver: yupResolver(organizationSchema),
    defaultValues: async () => {
      return { ...defaultValues, phone: parseStringToPhone(defaultValues.phone).formattedNumber };
    },
  });
  const { phone } = useWatch({ control });

  // Индикатор загрузки данных формы
  const [isPending, setPendingStatus] = useState(false);

  const onOrganizationUpdate: SubmitHandler<OrganizationCreationFields> = async (values) => {
    setPendingStatus(true);

    await OrganizationsAPI.updateOrganization(organizationId, values)
      .then(() => {
        onSuccessResponse();
        onClose();
      })
      .finally(() => setPendingStatus(false));
  };

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      <div className={cl(styles["content"])}>
        <h2 className={cl(styles["content__title"])}>Данные организации</h2>
        <form onSubmit={handleSubmit(onOrganizationUpdate)} className={cl(styles["form"])}>
          <TextField
            label='Полное название организации'
            {...register("fullName")}
            error={!!errors["fullName"]}
            helperText={errors["fullName"]?.message}
          />
          <TextField
            label='Краткое название организации'
            {...register("shortName")}
            error={!!errors["shortName"]}
            helperText={errors["shortName"]?.message}
          />
          <TextField
            label='E-mail организации'
            {...register("email")}
            error={!!errors["email"]}
            helperText={errors["email"]?.message}
          />
          <TextField
            label='Контактный телефон'
            placeholder='+7 ( ___ ) ___ - __ - __'
            {...register("phone")}
            error={!!errors["phone"]}
            helperText={errors["phone"]?.message}
            value={parseStringToPhone(phone ?? "").formattedNumber}
          />
          <div className={cl(styles["form__fields"])}>
            <TextField label='ИНН' {...register("inn")} error={!!errors["kpp"]} helperText={errors["inn"]?.message} />
            <TextField label='КПП' {...register("kpp")} error={!!errors["kpp"]} helperText={errors["kpp"]?.message} />
            <TextField label='БИК' {...register("bik")} error={!!errors["bik"]} helperText={errors["bik"]?.message} />
          </div>
          <TextField
            label='Юридический адрес'
            {...register("legalAddress")}
            error={!!errors["legalAddress"]}
            helperText={errors["legalAddress"]?.message}
            className={cl(styles["form__field_for-address"])}
          />
          <TextField
            label='Фактический адрес'
            {...register("actualAddress")}
            error={!!errors["actualAddress"]}
            helperText={errors["actualAddress"]?.message}
            className={cl(styles["form__field_for-address"])}
          />
          <TextField
            label='Почтовый индекс'
            {...register("postcode")}
            error={!!errors["postcode"]}
            helperText={errors["postcode"]?.message}
          />
          <TextField
            label='Расчетный счет'
            {...register("accountNumber")}
            error={!!errors["accountNumber"]}
            helperText={errors["accountNumber"]?.message}
          />
          <div className={cl(styles["form__buttons"])}>
            <LoadingButton
              size='large'
              type='submit'
              variant='contained'
              disabled={!isValid || isPending}
              loading={isPending}
            >
              Сохранить
            </LoadingButton>
            <Button size='large' type='button' variant='outlined' onClick={onClose}>
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </PopupLayout>
  );
};
