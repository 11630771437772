import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Services
import { EmployeesAPI } from "services";

// Components
import { Invitation } from "components/Elements";
// Popups
import { CallbackPopup } from "popups";

// Scss
import styles from "./OrganizationInvitations.module.scss";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchUserData } from "store/actions/UserActions";

// Typescript
import { IOrganizationEmployee } from "types/models";

export const OrganizationInvitations: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const popupManager = usePopupManager();

  const { user } = useAppSelector((state) => state.userReducer);
  const [invitations, setInvitations] = useState<IOrganizationEmployee[]>([]);

  const loadInvitations = async () => {
    const dbInvitations = await EmployeesAPI.findInvitations();
    setInvitations(dbInvitations.rows);
  };

  useEffect(() => {
    loadInvitations();
  }, []);

  const onInvitationAccept = useCallback(
    async (organizationId: string) => {
      await EmployeesAPI.updateInvitation({ organizationId, status: "accept" }).then(() => {
        enqueueSnackbar("Приглашение в организацию успешно принято", { variant: "success" });
        dispatch(fetchUserData());
        loadInvitations();
      });
    },
    [dispatch, enqueueSnackbar],
  );

  const onInvitationAcceptClick = useCallback(
    async (organizationId: string | number) => {
      if (user?.organizations[0]) {
        popupManager.open(CallbackPopup, {
          title: "Вы уверены, что хотите принять приглашение?",
          subtitle: `Вы уже состоите в организации ${user?.organizations[0].details.shortName}. После принятия данного приглашения Вы покинете старую организацию и присоединитесь к новой`,
          submitButtonText: "Принять",
          cancelButtonText: "Отмена",
          onSubmit: () => onInvitationAccept(organizationId + ""),
        });
      } else {
        onInvitationAccept(organizationId + "");
      }
    },
    [onInvitationAccept, popupManager, user?.organizations],
  );

  const onInvitationDecline = useCallback(
    async (organizationId: string | number) => {
      await EmployeesAPI.updateInvitation({ organizationId: organizationId + "", status: "decline" }).then(() => {
        enqueueSnackbar("Приглашение в организацию успешно отклонено", { variant: "success" });
      });
    },
    [enqueueSnackbar],
  );

  const InvitationsComponents = useMemo(
    () =>
      invitations.map((invitation) => {
        return (
          <Invitation
            key={invitation.id}
            id={invitation.organizationId}
            title={`Приглашение в организацию – ${invitation.organization.details.shortName}`}
            subtitle='Вам необходимо подтвердить или отклонить данное приглашение'
            acceptButtonText='Присоединиться'
            declineButtonText='Отклонить'
            onAccept={onInvitationAcceptClick}
            onDecline={onInvitationDecline}
          />
        );
      }),
    [invitations, onInvitationAcceptClick, onInvitationDecline],
  );

  return invitations.length > 0 ? <ul className={cl(styles["invitations"])}>{InvitationsComponents}</ul> : <></>;
};
