import React, { useCallback, useState } from "react";
import cl from "classnames";

// Services
import { UsersAPI } from "services/Passport";

// Layouts
import { ContentBox } from "layouts";
// Components
import { Loader } from "components/Elements";
// Blocks
import { UsersTable } from "blocks";

// Scss
import styles from "./Users.module.scss";

// Typescript
import { IUser } from "types/models";

export const Users: React.FC = (): JSX.Element => {
  const [isPending, setPendingStatus] = useState(true);

  const [users, setUsers] = useState<IUser[]>([]);
  const [maxCount, setMaxCount] = useState(1);

  const [isAccountSectionExpanded, setAccountSectionView] = useState(true);
  const handleOrganizationExpand = () => setAccountSectionView((state) => !state);

  const loadUsers = useCallback(async (page: number) => {
    setPendingStatus(true);

    const { rows, count } = await UsersAPI.findAll(page + 1, 10, ["withACL"]);
    setMaxCount(count);
    setUsers(rows);

    setTimeout(() => setPendingStatus(false), 200);
  }, []);

  return (
    <>
      <ContentBox
        isExpandActive
        onExpand={handleOrganizationExpand}
        expanded={isAccountSectionExpanded}
        title='Пользователи'
      >
        <div className={cl(styles["section"])}>
          <div className={cl(styles["section__separator"])} />
          <div className={cl(styles["section__table-wrapper"])}>
            <Loader isActive={isPending} />
            <UsersTable users={users} maxCount={maxCount} onTableChange={loadUsers} />
          </div>
        </div>
      </ContentBox>
    </>
  );
};
