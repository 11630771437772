import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import FingerprintJS, { Agent } from "@fingerprintjs/fingerprintjs";
import addDays from "date-fns/addDays";
import Cookies from "js-cookie";

// Redux
import { store } from "index";
import { userSlice } from "store/reducers/UserSlice";

// Typescript
import { HttpError } from "utils/HttpError";
import { enqueueSnackbar } from "notistack";

export class GlobalAPI {
  axios: AxiosInstance;

  fingerPrintJS: Promise<Agent>;

  staticHeaders: Record<string, string> =
    process.env.NODE_ENV === "development" ? { "x-lang": "ru", "x-real-ip": "192.168.1.10" } : { "x-lang": "ru" };

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1",
      withCredentials: true,
    });

    this.fingerPrintJS = FingerprintJS.load();

    this._handleError = this._handleError.bind(this);
    this._extendHeaders = this._extendHeaders.bind(this);

    this.axios.interceptors.response.use((resp) => resp, this._handleError);
    this.axios.interceptors.request.use(this._extendHeaders);
  }

  async _extendHeaders(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const { userReducer } = store.getState();
    const { authToken } = userReducer;

    if (authToken) {
      config.headers = { ...config.headers, Authorization: "Bearer " + authToken };
    } else {
      const newTokenData = await this._loadToken().catch(() => {
        config = {
          ...config,
          cancelToken: new axios.CancelToken((cancel) => cancel("Cancel repeated request")),
        };
      });

      if (newTokenData) {
        config.headers = { ...config.headers, Authorization: "Bearer " + newTokenData.authToken };
        store.dispatch(userSlice.actions.setAuthToken(newTokenData.authToken));
      }
    }

    config.headers = { ...config.headers, "x-user-fingerprint": Cookies.get("rms_fingerprint") ?? "" };

    return config;
  }

  async _loadToken(): Promise<{ authToken: string; expiresIn: number }> {
    if (!Cookies.get("rms_fingerprint")) {
      const fp = await this.fingerPrintJS;
      const newFingerprint = await fp.get();
      Cookies.set("rms_fingerprint", newFingerprint.visitorId, {
        secure: true,
        expires: addDays(new Date(), 3),
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
    }

    const baseUrl = process.env.REACT_APP_PASSPORT_BASE_URL || "http://localhost:4000/v1";

    return axios
      .get(`${baseUrl}/sessions/users/actions/refresh-tokens`, {
        headers: { "x-user-fingerprint": Cookies.get("rms_fingerprint") ?? "", ...this.staticHeaders },
        withCredentials: true,
      })
      .then((res) => res.data);
  }

  async _handleError(error: AxiosError): Promise<ErrorConstructor> {
    if (error.response?.status === 401) {
      const newToken = await this._loadToken();

      if (error.config && newToken) {
        store.dispatch(userSlice.actions.setAuthToken(newToken.authToken));
        error.config.headers = {
          ...error.config.headers,
          ...this.staticHeaders,
          Authorization: "Bearer " + newToken.authToken,
        };

        error.config.baseURL = undefined;
        return this.axios.request(error.config);
      }
    }

    if (error.response) {
      enqueueSnackbar(error.response.data.message ?? "Возникла ошибка при выполнении запроса", { variant: "error" });
      throw new HttpError(error.response.data.statusCode, error.response.data.message);
    }

    throw new HttpError();
  }

  _catchErrorWithoutNotification(error: AxiosError): Promise<ErrorConstructor> {
    if (error.response) {
      throw new HttpError(error.response.data.status, error.response.data.message);
    }

    throw new HttpError();
  }
}
