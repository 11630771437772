import React from "react";
import cl from "classnames";

// Images
import {
  ApiOutlinedIcon,
  BusinessCenterOutlinedIcon,
  FeedbackOutlinedIcon,
  GroupsOutlinedIcon,
  HttpsOutlinedIcon,
  PeopleAltOutlinedIcon,
  PersonOutlineOutlinedIcon,
} from "assets/icons";

// Components
import { MenuGroup, MenuLink } from "components/Elements";

// Redux
import { useAppSelector } from "hooks/redux";

// Styles
import styles from "./SettingsMenuGroup.module.scss";

// Utils
import { checkGlobalAccess, checkOrganizationAccess } from "utils/ACL";

export const SettingsMenuGroup: React.FC = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <aside className={cl(styles["menu-container"])}>
      <ul className={cl(styles["menu-container__links-groups"])}>
        <MenuGroup name='Настройки аккаунта'>
          <MenuLink name='Профиль' to='/settings/account' icon={PersonOutlineOutlinedIcon} />
          <MenuLink name='Безопасность' to='/settings/security' icon={HttpsOutlinedIcon} />
          <MenuLink
            name='Обратная связь'
            target='_blank'
            to={process.env.REACT_APP_FEEDBACK_URL ?? "#"}
            icon={FeedbackOutlinedIcon}
          />
        </MenuGroup>

        {checkGlobalAccess("passport.users.viewer", "passport.applications.viewer") && (
          <MenuGroup name='Настройки системы'>
            {checkGlobalAccess("passport.users.viewer") && (
              <MenuLink name='Пользователи' to='/settings/users' icon={PeopleAltOutlinedIcon} />
            )}
          </MenuGroup>
        )}

        {(user?.organizations ?? []).length > 0 && (
          <MenuGroup name='Настройки организации'>
            <MenuLink name='Организация' to='/settings/organization' icon={BusinessCenterOutlinedIcon} end />
            {checkOrganizationAccess("organization.employees.viewer") && (
              <MenuLink name='Сотрудники' to='/settings/organization/employees' icon={GroupsOutlinedIcon} />
            )}
            {checkOrganizationAccess("organization.applications.viewer") && (
              <MenuLink name='Приложения' to='/settings/organization/applications' icon={ApiOutlinedIcon} />
            )}
          </MenuGroup>
        )}
      </ul>
    </aside>
  );
};
