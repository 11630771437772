import React, { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

// Services
import { EmployeesAPI } from "services";

// Layouts
import { PopupLayout } from "layouts";
// Components
import { AccessLevelSearch } from "components/Inputs";

// Scss
import styles from "./EmployeeInvitePopup.module.scss";

// Typescript
import { EmployeeInvitePopupProps } from "./EmployeeInvitePopup.props";
import { MicroserviceACL } from "types/models";

export const EmployeeInvitePopup: React.FC<EmployeeInvitePopupProps> = ({
  isOpen,
  organizationId,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setUserEmail] = useState("");
  const [employeeACL, setEmployeeACL] = useState<string[]>([]);
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const handleEmailFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setUserEmail(evt.target.value);
  };

  const handleFormSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setFormSubmitting(true);

    return EmployeesAPI.invite(organizationId, { email, acl: employeeACL })
      .then(() => {
        enqueueSnackbar(`Приглашение пользователю (${email}) успешно отправлено на указанный адрес электронной почты`, {
          variant: "success",
        });

        onClose();
      })
      .finally(() => setFormSubmitting(false));
  };

  const handleACLSelect = (suggestion: MicroserviceACL) => {
    setEmployeeACL((state) => {
      return state.includes(suggestion.id + "") ? state : state.filter((acl) => acl !== suggestion.id);
    });
  };

  const handleACLUnselect = (id: string) => () => {
    setEmployeeACL((state) => state.filter((acl) => acl !== id));
  };

  const SelectedACLComponents = useMemo(
    () => employeeACL.map((acl) => <Chip key={acl} label={acl} onDelete={handleACLUnselect(acl)} />),
    [employeeACL],
  );

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      <div className={cl(styles["popup__content"])}>
        <h2 className={cl(styles["popup__title"])}>Добавление нового сотрудника</h2>
        <form className={cl(styles["popup__form"])} onSubmit={handleFormSubmit}>
          <TextField
            required
            name='email'
            type='email'
            value={email}
            label='E-mail пользователя'
            placeholder='Введите email сотрудника'
            onChange={handleEmailFieldChange}
          />
          <AccessLevelSearch filter='organization' onSuggestionSelect={handleACLSelect} selectedACL={employeeACL} />
          {SelectedACLComponents.length > 0 && (
            <ul className={cl(styles["popup__acl-list"])}>{SelectedACLComponents}</ul>
          )}
          <div className={cl(styles["popup__buttons"])}>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isFormSubmitting}
              disabled={isFormSubmitting}
            >
              Отправить приглашение
            </LoadingButton>
            <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
              Отмена
            </Button>
          </div>
        </form>
      </div>
    </PopupLayout>
  );
};
