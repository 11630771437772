import React from "react";
import cl from "classnames";

// Components
import { ReactComponent as ExpandSVG } from "assets/icons/expand.svg";

// Scss
import styles from "./ContentBox.module.scss";

// Typescript
import { IContentBox } from "./ContentBox.props";

const ContentBox: React.FC<IContentBox> = ({ title, isExpandActive, expanded, children, onExpand }): JSX.Element => {
  return (
    <div
      className={cl(styles["content-box"], {
        [styles["content-box_expanded"]]: expanded,
      })}
    >
      <div className={cl(styles["content-box__header"])}>
        {title && <h2 className={cl(styles["content-box__title"])}>{title}</h2>}
        {isExpandActive && <ExpandSVG onClick={onExpand} className={cl(styles["content-box__expand-btn"])} />}
      </div>
      <div className={cl(styles["content-box__content"])}>{children}</div>
    </div>
  );
};

export default React.memo(ContentBox);
