import format from "date-fns/format";

import { Column, GenericTableProps } from "blocks";
import { IApplication } from "types/models";

export interface ApplicationsTableProps extends GenericTableProps {
  applications: IApplication[];
}

export const applicationsTableColumns: Column[] = [
  { id: "id", label: "Идентификатор" },
  { id: "description", label: "Описание" },
  {
    id: "createdAt",
    label: "Дата создания",
    format: (value) => {
      return format(new Date(value as string), "dd.MM.yyyy / HH:mm");
    },
  },
];
