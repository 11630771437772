import React, { useEffect, useState } from "react";
import cl from "classnames";

import { ReactComponent as LoaderSVG } from "assets/images/loader.svg";

// Scss
import styles from "./Loader.module.scss";

// Typescript
import { LoaderProps } from "./Loader.props";

export const Loader: React.FC<LoaderProps> = ({ isActive, className, ...props }): JSX.Element => {
  const [isRendered, setRendered] = useState(false);

  useEffect(() => {
    if (isActive) {
      setRendered(true);
    } else {
      setTimeout(() => setRendered(false), 201);
    }
  }, [isActive]);

  return isRendered ? (
    <div
      className={cl(
        styles["loader"],
        {
          [styles["loader_active"]]: isActive,
        },
        className,
      )}
      {...props}
    >
      <LoaderSVG className={cl(styles["loader__icon"])} />
    </div>
  ) : (
    <></>
  );
};
