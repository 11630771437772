import { GlobalAPI } from "../GlobalAPI";

// Typescript
import { MultiResponse } from "types/MultiResponse";
import { IMicroservice, MicroserviceACL } from "types/models";

class MicroServicesAPI extends GlobalAPI {
  findAll(): Promise<IMicroservice[]> {
    return this.axios.get("/microservices").then((res) => res.data);
  }

  findAllACL(
    filterOptions: Partial<ACLFilterOptions>,
    page = 1,
    amount = 100,
  ): Promise<MultiResponse<MicroserviceACL>> {
    return this.axios.get("/microservices/acl", { params: { ...filterOptions, page, amount } }).then((res) => res.data);
  }
}

export interface ACLFilterOptions {
  search: string;
  type: string;
  exclude: string[];
  services: string[];
}

export default new MicroServicesAPI();
