import React from "react";
import cl from "classnames";

// Material UI
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

// Components
import { CloseOutlinedIcon } from "assets/icons";

// Scss
import styles from "./PopupLayout.module.scss";

// Typescript
import { PopupLayoutProps } from "./PopupLayout.props";

export const PopupLayout: React.FC<PopupLayoutProps> = ({
  title,
  isOpen,
  children,
  icon: Icon,
  onClose,
}): JSX.Element => {
  return (
    <Dialog
      id='popup'
      open={isOpen ?? false}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: "max-content", overflowY: "initial" } }}
    >
      <IconButton
        size='large'
        disableRipple
        disableFocusRipple
        sx={{
          position: "absolute",
          right: "-32px",
          top: "-32px",
          color: "white",
          transition: "all 0.2s linear",
          padding: "4px",
          ":hover": { color: "white", opacity: "0.6" },
        }}
        onClick={onClose}
      >
        <CloseOutlinedIcon fontSize='inherit' />
      </IconButton>
      <div className={cl(styles["popup__wrapper"])}>
        <div className={cl(styles["popup__content"])}>
          {Icon && <Icon className={cl(styles["popup__icon"])} />}
          <h2 className={cl(styles["popup__title"])}>{title}</h2>
          {children}
        </div>
      </div>
    </Dialog>
  );
};
