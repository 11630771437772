import React, { useCallback, useMemo, useState } from "react";
import cl from "classnames";

// Material UI
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// Components
import { AccessLevelSearch } from "components/Inputs";
// Layouts
import { PopupLayout } from "layouts";

// Scss
import styles from "./UserAccessPopup.module.scss";

// Typescript
import { UserAccessPopupProps } from "./UserAccessPopup.props";
import { MicroserviceACL } from "types/models";

export const UserAccessPopup: React.FC<UserAccessPopupProps> = ({
  title,
  isOpen,
  instance,
  initialACL,
  onFormSubmit,
  onClose = () => {},
}): JSX.Element => {
  const [selectedACL, setSelectedACL] = useState<string[]>(initialACL);

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const handleFormSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault();

      setFormSubmitting(true);

      onFormSubmit(instance, selectedACL)
        .then(() => onClose())
        .finally(() => setFormSubmitting(false));
    },
    [onClose, onFormSubmit, selectedACL, instance],
  );

  const handleSuggestionClick = (suggestion: MicroserviceACL) => {
    setSelectedACL((state) => {
      return state.findIndex((acl) => acl === suggestion.id) === -1 ? [...state, suggestion.id] : state;
    });
  };

  const handleSelectedACLDelete = (acl: string) => (): void => {
    setSelectedACL((state) => state.filter((i) => i !== acl));
  };

  const SelectedACLElements = useMemo(
    () => selectedACL.map((acl) => <Chip key={acl} label={acl} onDelete={handleSelectedACLDelete(acl)} />),
    [selectedACL],
  );

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      <div className={cl(styles["popup__content"])}>
        <h2 className={cl(styles["popup__title"])}>{title}</h2>
        <div className={cl(styles["instance-info"])}>
          <h3 className={cl(styles["instance-info__name"])}>{instance.name}</h3>
          <a href={`mailto:${instance.email}`} className={cl(styles["instance-info__email"])}>
            {instance.email}
          </a>
        </div>
        <form onSubmit={handleFormSubmit} className={cl(styles["form"])}>
          <AccessLevelSearch filter='all' onSuggestionSelect={handleSuggestionClick} selectedACL={selectedACL} />
          {selectedACL.length > 0 && <ul className={cl(styles["form__list"])}>{SelectedACLElements}</ul>}
          <div className={cl(styles["form__buttons"])}>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isFormSubmitting}
              disabled={isFormSubmitting}
            >
              Сохранить
            </LoadingButton>
            <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
              Отменить
            </Button>
          </div>
        </form>
      </div>
    </PopupLayout>
  );
};
