import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

// Components
import { Loader } from "components/Elements";
// Layouts
import { Footer, ProtectedRoute } from "layouts";
import { HeaderWrapper } from "blocks";
// Pages
import { ChangePassword, Login, Register, Reset, Home, PhoneLogin, CreateOrganization } from "pages";

// Redux
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { fetchUserData } from "store/actions/UserActions";

const SettingsPage = lazy(() => import("pages/Protected/Settings/Settings"));

export const App: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { isAuthorized, user } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    const referer = searchParams.get("referer");
    if (referer) sessionStorage.setItem("RMS_referer", referer);
  }, [searchParams]);

  useEffect(() => {
    if (isAuthorized && sessionStorage.getItem("RMS_referer")) {
      window.location.replace(sessionStorage.getItem("RMS_referer") ?? "");
    }
  }, [isAuthorized]);

  return (
    <>
      {isAuthorized !== null ? (
        <>
          <HeaderWrapper />

          <Routes>
            <Route
              path='/settings/*'
              element={
                <ProtectedRoute
                  element={
                    <Suspense fallback={<Loader isActive={true} />}>
                      <SettingsPage />
                    </Suspense>
                  }
                />
              }
            />

            <Route path='/home' element={<ProtectedRoute element={<Home />} />} />

            <Route
              path='/create-organization'
              element={
                (user?.organizations ?? []).length > 0 ? (
                  <Navigate to='/home' />
                ) : (
                  <ProtectedRoute
                    element={
                      <Suspense fallback={<Loader isActive={true} />}>
                        <CreateOrganization />
                      </Suspense>
                    }
                  />
                )
              }
            />

            {/* Если авторизован, всегда перекидываем на домашнюю со всех глобальных роутов */}
            {isAuthorized ? (
              <Route path='*' element={<Navigate to='/home' />} />
            ) : (
              <>
                <Route path='/reset' element={<Reset />} />
                <Route path='/recovery' element={<ChangePassword />} />
                <Route path='/sign-in' element={<Login />} />
                <Route path='/sign-up' element={<Register />} />
                <Route path='/sign-in-mobile' element={<PhoneLogin />} />
              </>
            )}

            <Route path='*' element={isAuthorized ? <Navigate to='/home' /> : <Navigate to='/sign-in' />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <Loader isActive />
      )}
    </>
  );
};
