import React from "react";
import { addSeconds, differenceInSeconds, format } from "date-fns";
import { useCountdown, useEffectOnce } from "usehooks-ts";

// Material UI
import Button from "@mui/material/Button";

// Typescript
import { CountdownButtonProps } from "./CountdownButton.props";
const CountdownButton: React.ForwardRefRenderFunction<HTMLButtonElement, CountdownButtonProps> = (
  { children, date, disabled, countDownTemplate, ...props },
  buttonRef,
): JSX.Element => {
  const [count, { startCountdown }] = useCountdown({
    countStart: differenceInSeconds(date, new Date()),
    intervalMs: 1000,
    countStop: 0,
  });

  useEffectOnce(() => {
    startCountdown();
  });

  return (
    <Button ref={buttonRef} disabled={disabled || count > 0} {...props}>
      {children}
      {count ? ` (${format(addSeconds(new Date(0), count), countDownTemplate)})` : ""}
    </Button>
  );
};

export default React.memo(React.forwardRef<HTMLButtonElement, CountdownButtonProps>(CountdownButton));
