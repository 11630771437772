import React, { useCallback, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import cl from "classnames";

// Material UI
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

// Services
import { EmployeesAPI } from "services";

// Images
import { PersonAddOutlinedIcon, SearchOutlinedIcon } from "assets/icons";
// Layouts
import { ContentBox } from "layouts";
// Blocks
import { EmployeesTable } from "blocks";
// Components
import { Loader } from "components/Elements";
// Popups
import { EmployeeInvitePopup } from "popups";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./Employees.module.scss";

// Typescript
import { IOrganizationEmployee } from "types/models";

export const Employees: React.FC = (): JSX.Element => {
  const popupManager = usePopupManager();

  const { user } = useAppSelector((state) => state.userReducer);

  const [maxCount, setMaxCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isPending, setPendingStatus] = useState(true);
  const [employees, setEmployees] = useState<IOrganizationEmployee[]>([]);

  const loadEmployeesInfo = useCallback(
    async (page: number) => {
      setPendingStatus(true);

      const organizationId = user?.organizations[0].id ?? "";
      const loadArray = new Array(page + 1).fill(0);
      let maxCount = 1;

      const dbEmployees = await Promise.all(
        loadArray.map(async (_, page) => {
          const dbResponse = await EmployeesAPI.findAllByOrganizationId(organizationId, searchText, page + 1, 10);
          maxCount = dbResponse.count;

          return dbResponse.rows;
        }),
      );

      setEmployees(dbEmployees.flat(1));
      setMaxCount(maxCount);

      setTimeout(() => setPendingStatus(false), 200);
    },
    [searchText, user?.organizations],
  );

  const handleSearchFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(evt.target.value);
  };

  const handleEmployeeCreateClick = () => {
    popupManager.open(EmployeeInvitePopup, {
      organizationId: user?.organizations[0].id + "",
    });
  };

  return (
    <>
      <ContentBox expanded title='Сотрудники организации'>
        <div className={cl(styles["header"])}>
          <TextField
            size='small'
            label='Поиск'
            value={searchText}
            onChange={handleSearchFieldChange}
            className={cl(styles["header__search"])}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            type='button'
            variant='outlined'
            startIcon={<PersonAddOutlinedIcon />}
            onClick={handleEmployeeCreateClick}
            className={cl(styles["header__button"])}
          >
            Добавить сотрудника
          </Button>
        </div>
        <i className={cl(styles["separator"])} />
        <div className={cl(styles["table"])}>
          <Loader isActive={isPending} />
          <EmployeesTable employees={employees} maxCount={maxCount} onTableChange={loadEmployeesInfo} />
        </div>
      </ContentBox>
    </>
  );
};
