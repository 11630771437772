import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import _get from "lodash/get";
import cl from "classnames";

// Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

// Services
import { SessionsAPI } from "services";

// Images
import { DeleteOutlineOutlinedIcon, MoreVertOutlinedIcon } from "assets/icons";
// Components
import { BaseModal, BaseModalButton } from "components/Modals";

// Scss
import styles from "./SessionsTable.module.scss";

// Typescript
import { SessionsTableProps, sessionsTableColumns } from "./SessionsTable.props";
import { ISession } from "types/models";

export const SessionsTable: React.FC<SessionsTableProps> = ({
  maxCount,
  sessions,
  currentSession,
  onTableChange,
}): JSX.Element => {
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);

  const handlePageChange = (evt: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onTableChange(currentPage);
  }, [currentPage, onTableChange]);

  const handleDotsClick = useCallback(
    (session: ISession) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSelectedRowsIds([session.id]);

      const target = evt.target as HTMLElement;
      const anchorEl = target.closest("svg") as SVGSVGElement;

      const buttons: BaseModalButton[] = [];

      buttons.push({
        key: "1",
        text: "Удалить",
        icon: DeleteOutlineOutlinedIcon,
        onClick: () => {
          SessionsAPI.removeOne(this ? session.id : -1).then(async () => {
            enqueueSnackbar(`Сессия №${session.id} успешно удалена`, { variant: "success" });
            await onTableChange(currentPage);
          });
        },
      });

      popupManager.open(BaseModal, { key: "sessions-modal", anchorEl, buttons, onClose: () => setSelectedRowsIds([]) });
    },
    [currentPage, enqueueSnackbar, onTableChange, popupManager],
  );

  const SessionsElementsRows = useMemo(
    () =>
      sessions.map((session) => (
        <TableRow color='hover' selected={selectedRowsIds.includes(session.id)} key={session.id}>
          {sessionsTableColumns.map(({ id, format, ...props }, idx) => {
            const value = _get(session, id);
            return (
              <TableCell key={id + idx} {...props}>
                {format ? format(value) : value}
              </TableCell>
            );
          })}
          <TableCell align='center' style={{ width: 48 }}>
            <IconButton
              disableFocusRipple
              type='button'
              disableRipple
              onClick={handleDotsClick(session)}
              sx={{ padding: 0 }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [handleDotsClick, selectedRowsIds, sessions],
  );

  return (
    <>
      <TableContainer sx={{ width: "calc(100% + 24px * 2)", marginLeft: "-24px", marginTop: "24px" }}>
        <Table>
          <TableHead>
            <TableRow>
              {sessionsTableColumns.map(({ id, label, format, ...props }, idx) => (
                <TableCell key={id + idx} {...props}>
                  {format ? label : label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {sessionsTableColumns.map(({ id, format, ...props }, idx) => {
                const value = _get(currentSession, id);
                return (
                  <TableCell key={id + idx} {...props}>
                    {format ? format(value) : value}
                  </TableCell>
                );
              })}
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}>
                <h3 className={cl(styles["table__subtitle"])}>Активные устройства</h3>
              </TableCell>
            </TableRow>
            {SessionsElementsRows}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={maxCount}
        rowsPerPage={10}
        page={currentPage}
        rowsPerPageOptions={[10]}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to, count }) => {
          return "Сессии " + from + "-" + to + " из " + count;
        }}
      />
    </>
  );
};
