import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Typescript
import { IUser } from "types/models/User";

export const ACLServiceName = "passport";

// 1. Описываем состояние в интерфейсе
export interface UserState {
  authToken: string | null;
  isAuthorized: null | boolean;
  user: IUser | null;

  // Права доступа пользователя в организации
  userOrganizationACL: string[];
  userGlobalACL: string[];
}

// 2. Подключаем и устанавливаем начальные значения в состояние
const initialState: UserState = {
  authToken: null,
  isAuthorized: null,
  user: null,

  userOrganizationACL: [],
  userGlobalACL: [],
};

// 3. Описываем функции для слайса (редьюсеры)
export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Установить токен авторизации для текущего пользователя
    setAuthToken(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
    },
    // Записать информацию о текущем пользователе
    setCurrentUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      state.isAuthorized = true;
      state.userGlobalACL = action.payload.acl.map((perm) => perm.microserviceACLId);

      if (action.payload.organizations[0]) {
        state.userOrganizationACL = action.payload.organizations[0].employeeInfo.acl.map((i) => i.microserviceACLId);
      }
    },
    // Обработчик ошибки авторизации
    handleLoginError(state) {
      state.isAuthorized = false;
      state.user = null;
      state.authToken = "";
    },
  },
});

// 4. Экспортируем и подключаем импортом там, где необходимо вызвать
export default userSlice.reducer;
