import { store } from "index";

// Redux
import { UserState } from "store/reducers/UserSlice";

// Permissions
import { PassportPermissions } from "types/PasspotPermissions.enum";

export const checkGenericAccess = (...acl: (keyof typeof PassportPermissions)[]): boolean => {
  const { userGlobalACL, userOrganizationACL } = store.getState().userReducer as UserState;
  return acl.some((perm) => checkAccessLevel([...userGlobalACL, ...userOrganizationACL], perm));
};

export const checkGlobalAccess = (...acl: (keyof typeof PassportPermissions)[]): boolean => {
  const { userGlobalACL } = store.getState().userReducer as UserState;
  return acl.some((perm) => checkAccessLevel(userGlobalACL, perm));
};

export const checkOrganizationAccess = (...acl: (keyof typeof PassportPermissions)[]): boolean => {
  const { userOrganizationACL } = store.getState().userReducer as UserState;
  return acl.some((perm) => checkAccessLevel(userOrganizationACL, perm));
};

// Массив уровней доступа
const actions = ["*", "admin", "editor", "viewer"];

/**
 * Функция, отвечающая за проверку у пользователя прав доступа определенного уровня
 *
 * @param permissionsArray массив ACL ("passport.users.viewer") пользователя
 * @param lowAccessPermissions итератор минимальных уровней доступа (работает по принципу логического "ИЛИ")
 * @returns true – пользователь имеет право доступа, false – у пользователя нет доступа
 */
export const checkAccessLevel = (permissionsArray: string[], ...lowAccessPermissions: string[]): boolean => {
  return lowAccessPermissions.reduce((prevStatus, lowAccessPermission) => {
    const iterationActions = [...actions];

    // Если у пользователя был результат прошлой проверки true, то возвращаем предидущий результат
    if (prevStatus) {
      return prevStatus;
    }

    // Разделяем ACL строку на части (по ".")
    // passport.users.viewer =>  ["passport", "users", "viewer"]
    const lowAccessPermissionParts: string[] = lowAccessPermission.split(".");

    // Теперь убираем из массива действий те, что ниже по значимости
    // Например, если передали editor, то убираем viewer
    const action = lowAccessPermissionParts.pop();
    const actionIndexInActionsArray = iterationActions.findIndex((el) => el === action);
    const availableActions = iterationActions.splice(0, actionIndexInActionsArray + 1);

    // Создаем статусную переменную для остановки цикла и сохранения результата
    let isPermissionsArrayContainsOneOf = false;

    do {
      // Объединяем части
      const availablePermissionString = lowAccessPermissionParts.join(".");

      // Проверяем, что массив прав пользователя имеет хотя бы одно вхождение в массив достпных прав
      // Например: ["passport.users.admin"] => ["passport.users.*", "passport.users.admin"]
      isPermissionsArrayContainsOneOf = availableActions.some((availableAction) => {
        return permissionsArray.includes(`${availablePermissionString}.${availableAction}`);
      });

      // Удаляем один уровень ACL с конца
      lowAccessPermissionParts.pop();
    } while (lowAccessPermissionParts.length > 0 && !isPermissionsArrayContainsOneOf);

    return isPermissionsArrayContainsOneOf;
  }, false);
};
