import React, { useRef, useState } from "react";
import { useLocation } from "react-router";
import cl from "classnames";

// Images
import { ReactComponent as LogoSVG } from "assets/images/logo.svg";
// Modals
import { ProfileModal } from "components/Modals";

// Scss
import styles from "./HeaderWrapper.module.scss";

// Redux
import { useAppSelector } from "hooks/redux";

const PUBLIC_PATHNAMES = ["/sign-in", "/sign-in-mobile", "/sign-up", "/reset", "/recovery"];

export const HeaderWrapper: React.FC = (): JSX.Element => {
  const popupAnchorRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const [isPopupActive, setPopupActive] = useState(false);

  const { user } = useAppSelector((state) => state.userReducer);

  const openPopup = () => setPopupActive(true);
  const closePopup = () => setPopupActive(false);

  return (
    <header className={cl(styles["header"])}>
      <div className={cl(styles["header__content"])}>
        <a href={process.env.REACT_APP_HOME_URL} target='_self'>
          <LogoSVG className={cl(styles["header__logo"])} />
        </a>

        {!PUBLIC_PATHNAMES.includes(pathname) && (
          <div onClick={openPopup} className={cl(styles["header__profile"])} ref={popupAnchorRef}>
            <img className={cl(styles["header__image"])} src={user?.image || ""} />
            <span className={cl(styles["header__fullName"])}>
              {user?.name} {user?.surname}
            </span>
          </div>
        )}
      </div>

      {!PUBLIC_PATHNAMES.includes(pathname) && (
        <ProfileModal isOpen={isPopupActive} anchorEl={popupAnchorRef.current} onClose={closePopup} />
      )}
    </header>
  );
};
