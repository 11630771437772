import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import ru from "date-fns/locale/ru";

// Typescript
import { Column, GenericTableProps } from "blocks";
import { ISession } from "types/models";

// Utils
import { detectUnitsByTime } from "utils/Time";

export interface SessionsTableProps extends GenericTableProps {
  currentSession: ISession;
  sessions: ISession[];
}

export const sessionsTableColumns: Column[] = [
  { id: "ip", label: "IP адрес" },
  { id: "userAgent", label: "Название клиента" },
  {
    id: "createdAt",
    label: "Дата / время входа",
    format: (value) => {
      return format(new Date(value as string), "dd.MM.yyyy / HH:mm");
    },
  },
  {
    id: "createdAt",
    label: "Продолжительность",
    format: (value) => {
      return formatDistanceToNowStrict(new Date(value as string), {
        locale: ru,
        unit: detectUnitsByTime(new Date(value as string).getTime()),
      });
    },
  },
];
