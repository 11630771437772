import React, { useState } from "react";

// Material UI
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

// Images
import { VisibilityOffOutlinedIcon, VisibilityOutlinedIcon } from "assets/icons";

// Typescript
import { PasswordInputProps } from "./PasswordInput.props";

const PasswordInput: React.ForwardRefRenderFunction<HTMLInputElement, PasswordInputProps> = (
  { ...props },
  ref,
): JSX.Element => {
  const [inputType, setInputType] = useState<"password" | "text">("password");

  const changeInputType = (status: "password" | "text") => () => {
    setInputType(status);
  };

  return (
    <TextField
      ref={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {inputType === "text" ? (
              <VisibilityOffOutlinedIcon onClick={changeInputType("password")} sx={{ cursor: "pointer" }} />
            ) : (
              <VisibilityOutlinedIcon onClick={changeInputType("text")} sx={{ cursor: "pointer" }} />
            )}
          </InputAdornment>
        ),
      }}
      {...props}
      type={inputType}
    />
  );
};

export default React.forwardRef(PasswordInput);
