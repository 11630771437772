import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Layouts
import { PopupLayout } from "layouts";

// Scss
import styles from "./CallbackPopup.module.scss";

// Typescript
import { CallbackPopupProps } from "./CallbackPopup.props";

export const CallbackPopup: React.FC<CallbackPopupProps> = ({
  title,
  subtitle,
  isOpen,
  submitButtonText,
  cancelButtonText,
  onSubmit,
  onCancel,
  onClose = () => {},
  ...props
}): JSX.Element => {
  const handleCancelClick = () => {
    if (onCancel) onCancel();
    onClose();
  };

  const handleSubmitClick = () => {
    onSubmit();
  };

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose} {...props}>
      <div className={cl(styles["content"])}>
        <h2 className={cl(styles["content__title"])}>{title}</h2>
        <p className={cl(styles["content__subtitle"])}>{subtitle}</p>
        <div className={cl(styles["content__buttons"])}>
          <Button size='large' type='button' variant='contained' onClick={handleSubmitClick}>
            {submitButtonText}
          </Button>
          <Button size='large' type='button' variant='outlined' onClick={handleCancelClick}>
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </PopupLayout>
  );
};
