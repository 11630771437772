import { Formats, ParserPhoneNumberResult, Resolver, parsePhoneNumber } from "phonumber";

export const PhoneFormats: Formats = {
  RU: { mask: "(###) ###-##-##" },
  BY: { mask: "## ### ## ##" },
  US: { mask: "###-###-####", withTail: true },
};

export const PhoneResolvers: Resolver[] = [
  {
    firstInputChars: { 9: { mode: "add" }, 8: { mode: "replace" } },
    resolveAs: { firstChar: 7, code: "RU" },
  },
];

export const parseStringToPhone = (rawPhone: string): ParserPhoneNumberResult => {
  return parsePhoneNumber(rawPhone, { formats: PhoneFormats, resolvers: PhoneResolvers });
};
