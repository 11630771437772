import React from "react";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Styles
import styles from "./ActionMessage.module.scss";

// Typescript
import { ActionMessageProps } from "./ActionMessage.props";

export const ActionMessage: React.FC<ActionMessageProps> = ({
  title,
  description,
  buttonText,
  className,
  onButtonClick,
  ...props
}): JSX.Element => {
  return (
    <div className={cl(styles["action-message"], className)} {...props}>
      <small className={cl(styles["action-message__title"])}>{title}</small>
      <span className={cl(styles["action-message__description"])}>{description}</span>
      <Button type='button' variant='text' onClick={onButtonClick}>
        {buttonText}
      </Button>
    </div>
  );
};
