import React from "react";
import { Navigate } from "react-router";

// Redux
import { useAppSelector } from "hooks/redux";

// Components
import { Loader } from "components/Elements";

// Typescript
import { ProtectedRouteProps } from "./ProtectedRoute.props";

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  // Redux
  const { isAuthorized } = useAppSelector((state) => state.userReducer);

  let component = <></>;

  if (isAuthorized === null) {
    component = <Loader />;
  }

  if (isAuthorized) {
    component = element;
  } else {
    component = <Navigate to='/sign-in' />;
  }

  return component;
};
