import React from "react";
import cl from "classnames";

// Scss
import styles from "./MicroService.module.scss";

// Typescript
import { IMicroservice } from "types/models";

const MicroService: React.FC<IMicroservice> = ({ translation, imageURL, resourceURL }): JSX.Element => {
  return (
    <li className={cl(styles["service"])}>
      <a target='_self' className={cl(styles["service__link"])} href={resourceURL}>
        <img alt='Иконка ресурса' src={imageURL} className={cl(styles["service__image"])} />
        <h2 className={cl(styles["service__title"])}>{translation?.title ?? "Неизвестно"}</h2>
        <p className={cl(styles["service__description"])}>{translation?.description ?? "Неизвестно"}</p>
      </a>
    </li>
  );
};

export default React.memo(MicroService);
