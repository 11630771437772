import React, { useState } from "react";
import cl from "classnames";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";

// Material UI
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

// Redux
import { useAppDispatch } from "hooks/redux";
import { userSlice } from "store/reducers/UserSlice";
import { fetchUserData } from "store/actions/UserActions";

// Services
import { SessionsAPI } from "services/Passport";

// Components
import { PasswordInput } from "components/Inputs";

// Schemas
import { signInSchema } from "utils/Schemas";

// Scss
import styles from "./Login.module.scss";

// Typescript
import { LoginFields } from "./Login.props";

export const Login: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginFields>({ mode: "all", resolver: yupResolver(signInSchema) });
  const [isSubmitting, setSubmitting] = useState(false);

  const onLoginRequestError = (error: AxiosError) => {
    const message = error.response?.data.message;

    if (error.response?.status === 409) {
      setError("email", { message });
      return;
    }

    if (error.response?.status === 400) {
      setError("password", { message, type: "value" });
      return;
    }

    enqueueSnackbar(message ?? "Неизвестная ошибка сервера", { variant: "error" });
  };

  const onFormSubmit: SubmitHandler<LoginFields> = async (values) => {
    setSubmitting(true);

    await SessionsAPI.login({ ...values })
      .then(({ authToken }) => {
        dispatch(userSlice.actions.setAuthToken(authToken));
        dispatch(fetchUserData());
      })
      .catch(onLoginRequestError)
      .finally(() => setSubmitting(false));
  };

  const goToPhoneLoginPage = () => {
    navigate("/sign-in-mobile");
  };

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Вход</title>
        <meta name='description' content='Страница входа в личный кабинет РМ Солюшн'></meta>
      </Helmet>

      <main className={cl(styles["content"])}>
        <h1 className={cl(styles["content__title"])}>Вход</h1>
        <form onSubmit={handleSubmit(onFormSubmit)} id='login' className={cl(styles["form"])}>
          <TextField
            fullWidth
            label='E-mail'
            type='email'
            {...register("email")}
            error={!!errors["email"]}
            helperText={errors["email"]?.message}
          />
          <NavLink to='/reset' className={cl(styles["form__forgot-text"])}>
            Забыли пароль?
          </NavLink>
          <PasswordInput
            fullWidth
            label='Пароль'
            variant='outlined'
            {...register("password")}
            error={!!errors["password"]}
            helperText={errors["password"]?.message}
            sx={{ marginTop: "8px" }}
          />
          <div className={cl(styles["form__buttons"])}>
            <LoadingButton
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Войти
            </LoadingButton>
            <Button size='large' variant='outlined' type='button' onClick={goToPhoneLoginPage}>
              Войти по номеру телефона
            </Button>
          </div>
        </form>
        <h2 className={cl(styles["content__register-text"])}>
          Еще нет аккаунта? <NavLink to='/sign-up'>Зарегистрируйтесь</NavLink>
        </h2>
      </main>
      {/* 
      <RenderWhen>
        <RenderWhen.If isTrue={loginError?.type === "block"}>
          <StatusPage
            title='Учетная запись заблокирована'
            subtitle='Ваша учетная запись была заблокирована. Если у Вас возникли вопросы, напишите нам письмо: support@rmgroup.pro'
            buttonText='На главную'
            onClick={handleButtonClick}
            status='error'
          />
        </RenderWhen.If>
        <RenderWhen.If isTrue={loginError === null}>
          <main className={cl(styles["content"])}>
            <h1 className={cl(styles["content__title"])}>Вход</h1>
            <form onSubmit={handleSubmit(onFormSubmit)} id='login' className={cl(styles["form"])}>
              <TextField title='E-mail' type='email' {...register("email")} error={errors["email"]?.message} />
              <NavLink to='/reset' className={cl(styles["form__forgot-text"])}>
                Забыли пароль?
              </NavLink>
              <PasswordInput title='Пароль' {...register("password")} error={errors["password"]?.message} />
              <div className={cl(styles["form__buttons"])}>
                <Button size='m' loading={isSubmitting} disabled={isSubmitting} type='submit' appearance='primary'>
                  Войти
                </Button>
                <Button size='m' appearance='ghost' type='button' onClick={goToPhoneLoginPage}>
                  Войти по номеру телефона
                </Button>
              </div>
            </form>
            <h2 className={cl(styles["content__register-text"])}>
              Еще нет аккаунта? <NavLink to='/sign-up'>Зарегистрируйтесь</NavLink>
            </h2>
          </main>
        </RenderWhen.If>
      </RenderWhen> */}
    </>
  );
};
