import { combineReducers, configureStore } from "@reduxjs/toolkit";

// Slices
import userReducer from "store/reducers/UserSlice";

// Создаем root reducer
const rootReducer = combineReducers({ userReducer });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setupStore = (): any => {
  return configureStore({
    devTools: process.env.NODE_ENV === "development",
    reducer: rootReducer,
    // Добавлено для отклбчение ошибки о передаче actions как массива
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
