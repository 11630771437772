import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import cl from "classnames";

// Material UI
import LoadingButton from "@mui/lab/LoadingButton";

// Components
import { PasswordInput } from "components/Inputs";
import { Loader } from "components/Elements";
import { StatusPage } from "pages";

// Services
import { UsersAPI } from "services/Passport";

// Utils
import { resetConfirmSchema } from "utils/Schemas";

// Scss
import styles from "./ChangePassword.module.scss";

export const ChangePassword: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const resetCode = searchParams.get("code");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Индикатор, который показывает, что срок действия запроса на восстановление пароля истек
  const [isResetRequestAlive, setResetRequestAliveStatus] = useState<boolean | null>(null);
  // Индикатор, показывающий, что форма успешно отправлена и пароль изменен
  const [isFormSubmitted, setFormSubmitStatus] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ password: string; repeatPassword: string }>({ mode: "all", resolver: yupResolver(resetConfirmSchema) });

  useEffect(() => {
    UsersAPI.checkConfirmationExistence(resetCode + "")
      .then(() => setTimeout(() => setResetRequestAliveStatus(true), 200))
      .catch(() => setTimeout(() => setResetRequestAliveStatus(false), 200));
  }, [resetCode]);

  const onFormSubmit: SubmitHandler<{ password: string; repeatPassword: string }> = async (values) => {
    setSubmitting(true);

    await UsersAPI.resetPassword(resetCode + "", values)
      .then(() => setFormSubmitStatus(true))
      .catch((error: AxiosError) => {
        const message = error.response?.data.message ?? "Неизвестная ошибка сервера";

        if (error.response?.status === 400) {
          setError("password", message);
          return;
        }

        enqueueSnackbar(message ?? "Возникла ошибка при сохранении нового пароля", { variant: "error" });
      })
      .finally(() => setSubmitting(false));
  };

  const goToLoginPage = () => navigate("/sign-in");

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Сброс пароля</title>
        <meta name='description' content='Страница входа в личный кабинет РМ Солюшн' />
      </Helmet>

      {isResetRequestAlive === null && <Loader isActive={isResetRequestAlive === null} />}

      {isFormSubmitted && (
        <StatusPage title='Пароль успешно изменен' status='success' buttonText='Войти' onClick={goToLoginPage} />
      )}

      {isResetRequestAlive === false && (
        <StatusPage
          title='Ссылка для восстановления устарела'
          subtitle='Ссылка для сброса пароля не действительна или уже не существует'
          status='error'
          buttonText='На главную'
          onClick={goToLoginPage}
        />
      )}

      {!isFormSubmitted && isResetRequestAlive === true && (
        <main className={cl(styles["content"])}>
          <h1 className={cl(styles["content__title"])}>Восстановление пароля</h1>
          <span className={cl(styles["content__subtitle"])}>Введите новый пароль</span>
          <form onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["form"])}>
            <PasswordInput
              variant='outlined'
              label='Новый пароль'
              {...register("password")}
              error={!!errors["password"]}
              helperText={errors["password"]?.message}
            />
            <PasswordInput
              variant='outlined'
              label='Повторите пароль'
              {...register("repeatPassword")}
              error={!!errors["repeatPassword"]}
              helperText={errors["repeatPassword"]?.message}
            />
            <LoadingButton
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Обновить пароль
            </LoadingButton>
          </form>
        </main>
      )}
    </>
  );
};
