import React, { useEffect, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// Services
import { UsersAPI } from "services/Passport";

// Layouts
import { ContentBox } from "layouts";
// Blocks
import { OrganizationInvitations } from "blocks";
// Components
import { ActionMessage, Loader } from "components/Elements";
import { UploadPhoto } from "components/Inputs";
// Images
import { EditOutlinedIcon } from "assets/icons";
import { ReactComponent as CheckedSVG } from "assets/icons/empty-checked.svg";
import { ReactComponent as OrgAbstractionSVG } from "assets/icons/org-abstraction.svg";

// Popups
import { EmailConfirm, PhoneConfirm, ProfileChange } from "popups";

// Utils
import { parseStringToPhone } from "utils/Phone";

// Scss
import styles from "./Account.module.scss";

// Typescript
import { IUser } from "types/models";

export const Account: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();
  const navigate = useNavigate();

  const [isPending, setPendingStatus] = useState(true);

  const [user, setUser] = useState<IUser | null>(null);
  const [isOrganizationSectionExpanded, setOrganizationSectionExpanded] = useState(true);

  // Метод загрузки данных пользователя
  const loadUserData = async (): Promise<void> => {
    setPendingStatus(true);

    await UsersAPI.findMe()
      .then((dbUser) => setUser(dbUser))
      .finally(() => setTimeout(() => setPendingStatus(false), 200));
  };

  useEffect(() => {
    loadUserData();
  }, []);

  // Слушатель клика по кнопке "редактировать" данные профиля
  const handleEditPopupOpen = () => {
    popupManager.open(ProfileChange, {
      userId: user?.id + "",
      defaultValues: {
        fullName: `${user?.surname} ${user?.name} ${user?.patronymic || ""}`,
        email: user?.email ?? "",
        phone: user?.phone ?? "",
      },
      onSuccessServerResponse: async () => {
        enqueueSnackbar("Указанная информация об аккаунте успешно обновлена", { variant: "success" });
        await loadUserData();
      },
    });
  };

  // Слушатель клика в информационном окошке "Подтвердите e-mail"
  const handleEmailConfirmationPopupOpen = () => {
    if (user) {
      popupManager.open(EmailConfirm, {
        user: user,
        onSuccessServerResponse: async () => {
          enqueueSnackbar("E-mail, привязанный к аккаунту успешно подтвержден", { variant: "success" });
          await loadUserData();
        },
      });
    }
  };

  // Слушатель клика в информационном окошке "Подтвердите телефон"
  const handlePhoneConfirmationPopupOpen = () => {
    if (user) {
      popupManager.open(PhoneConfirm, {
        user: user,
        onSuccessServerResponse: async () => {
          enqueueSnackbar("Номер телефона, привязанный к аккаунту успешно подтвержден", { variant: "success" });
          await loadUserData();
        },
      });
    }
  };

  // Колбэк функция, срабатывающая после успешного обрезания фотографии профиля
  // Выполняет сохранение фотографии на сервере и делает перезагрузку страницы
  const onSuccessPhotoSave = async (croppedFile: Blob) => {
    setPendingStatus(true);
    const formData = new FormData();
    formData.append("photo", croppedFile);

    return UsersAPI.appendPhoto(user?.id + "", formData).then(async () => {
      enqueueSnackbar("Фотография профиля успешно обновлена", { variant: "success" });
      window.location.reload();
    });
  };

  // Слушатель раскрытия информационного блока с организацией
  const handleOrganizationSectionExpand = () => setOrganizationSectionExpanded((state) => !state);

  const handleCreateOrganizationClick = () => {
    navigate("/create-organization");
  };

  return (
    <>
      <Loader isActive={isPending} />

      <OrganizationInvitations />

      <div className={cl(styles["user-info"])}>
        <UploadPhoto imageUrl={user?.image ?? ""} onSuccessPhotoSave={onSuccessPhotoSave} />

        <div>
          <div className={cl(styles["user-info__name-box"])}>
            <div className={cl(styles["user-info__fullname-box"])}>
              <h2 className={cl(styles["user-info__fullname"])}>{`${user?.surname || ""} ${user?.name || ""} ${
                user?.patronymic || ""
              }`}</h2>

              <Tooltip title='Редактировать профиль'>
                <IconButton type='button' onClick={handleEditPopupOpen}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
            <span className={cl(styles["user-info__id"])}>ID: {user?.id}</span>
          </div>
          <ul className={cl(styles["user-info__contacts"])}>
            <li className={cl(styles["contact"])}>
              <h3 className={cl(styles["contact__title"])}>Телефон</h3>
              <div className={cl(styles["contact__value-box"])}>
                <span className={cl(styles["contact__value"])}>
                  {parseStringToPhone(user?.phone || "").formattedNumber}
                </span>
                {user?.isPhoneConfirmed && <CheckedSVG className={cl(styles["contact__confirmation"])} />}
              </div>
            </li>
            <li className={cl(styles["contact"])}>
              <h3 className={cl(styles["contact__title"])}>Email</h3>
              <div className={cl(styles["contact__value-box"])}>
                <span className={cl(styles["contact__value"])}>{user?.email}</span>
                {user?.isEmailConfirmed && <CheckedSVG className={cl(styles["contact__confirmation"])} />}
              </div>
            </li>
          </ul>
        </div>

        {user !== null && !user.isEmailConfirmed && (
          <ActionMessage
            title='Подтвердите email'
            buttonText='Подтвердить email'
            description='Для получения статуса подтвержденного пользователя'
            onButtonClick={handleEmailConfirmationPopupOpen}
            className={cl(styles["user-info__action-message"])}
          />
        )}

        {user !== null && user.isEmailConfirmed && !user.isPhoneConfirmed && (
          <ActionMessage
            title='Подтвердите телефон'
            buttonText='Подтвердить телефон'
            description='Для получения статуса подтвержденного пользователя'
            onButtonClick={handlePhoneConfirmationPopupOpen}
            className={cl(styles["user-info__action-message"])}
          />
        )}
      </div>

      {!user?.organizations[0] && (
        <ContentBox
          isExpandActive
          title='Организация'
          onExpand={handleOrganizationSectionExpand}
          expanded={isOrganizationSectionExpanded}
        >
          <div className={cl(styles["organization-box"])}>
            <OrgAbstractionSVG className={cl(styles["organization-box__image"])} />
            <p className={cl(styles["organization-box__text"])}>
              Вы можете добавить организацию, если являетесь ее представителем
            </p>
            <Button
              size='large'
              type='button'
              variant='contained'
              onClick={handleCreateOrganizationClick}
              sx={{ width: "220px", margin: "0 0 0 auto" }}
            >
              Добавить
            </Button>
          </div>
        </ContentBox>
      )}
    </>
  );
};
