import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import _get from "lodash/get";

// Material UI
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";

// Services
import { EmployeesAPI } from "services";

// Popups
import { CallbackPopup, UserAccessInstance, UserAccessPopup } from "popups";
// Modals
import { BaseModal, BaseModalButton } from "components/Modals";
// Images
import { DeleteOutlineOutlinedIcon, FormatListBulletedOutlinedIcon, MoreVertOutlinedIcon } from "assets/icons";

// Typescript
import { EmployeesTableProps, employeesTableColumns } from "./EmployeesTable.props";
import { IOrganizationEmployee } from "types/models";

// Utils
import { checkOrganizationAccess } from "utils/ACL";

export const EmployeesTable: React.FC<EmployeesTableProps> = ({ employees, maxCount, onTableChange }): JSX.Element => {
  const popupManager = usePopupManager();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);

  const handlePageChange = (evt: React.MouseEvent | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    onTableChange(currentPage);
  }, [currentPage, onTableChange]);

  const onEmployeeDelete = useCallback(
    async (employee: IOrganizationEmployee) => {
      return EmployeesAPI.deleteEmployee(employee.id).then(async () => {
        setSelectedRowsIds([]);
        enqueueSnackbar(`Сотрудник (${employee.user.email}) успешно удален из организации`, { variant: "success" });
        await onTableChange(currentPage);
      });
    },
    [currentPage, enqueueSnackbar, onTableChange],
  );

  const onEmployeeACLFormSubmit = useCallback(
    async (instance: UserAccessInstance, aclList: string[]) => {
      return EmployeesAPI.updateEmployeeACL(instance.id, aclList).then(async () => {
        setSelectedRowsIds([]);
        enqueueSnackbar(`Права доступа сотрудника (${instance.email}) успешно обновлены`, { variant: "success" });
        await onTableChange(currentPage);
      });
    },
    [currentPage, enqueueSnackbar, onTableChange],
  );

  const handleDotsClick = useCallback(
    (employee: IOrganizationEmployee) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSelectedRowsIds([employee.id]);

      const target = evt.target as HTMLElement;
      const anchorEl = target.closest("svg") as SVGSVGElement;

      const buttons: BaseModalButton[] = [];

      if (checkOrganizationAccess("organization.employees.admin")) {
        buttons.push(
          {
            key: "1",
            icon: FormatListBulletedOutlinedIcon,
            text: "Настроить права доступа",
            onClick: () => {
              const { user } = employee;
              popupManager.open(UserAccessPopup, {
                title: "Настройки прав доступа сотрудника",
                instance: { id: employee.id, email: user.email, name: `${user.surname} ${user.name}` },
                onlyPublicACL: false,
                initialACL: employee.acl.map((acl) => acl.microserviceACLId),
                onFormSubmit: onEmployeeACLFormSubmit,
              });
            },
          },
          {
            key: "2",
            icon: DeleteOutlineOutlinedIcon,
            text: "Удалить",
            onClick: () => {
              popupManager.open(CallbackPopup, {
                title: "Подтверждение удаления",
                subtitle: `Вы уверены, что хотите удалить сотрудника (${employee.user.email}) из данной организации?`,
                submitButtonText: "Удалить",
                cancelButtonText: "Отменить",
                onSubmit: () => onEmployeeDelete(employee),
              });
            },
          },
        );
      }

      popupManager.open(BaseModal, { key: "employee-modal", anchorEl, buttons });
    },
    [onEmployeeACLFormSubmit, onEmployeeDelete, popupManager],
  );

  const UsersElementsRows = useMemo(
    () =>
      employees.map((employee) => (
        <TableRow color='hover' selected={selectedRowsIds.includes(employee.id)} key={employee.id}>
          {employeesTableColumns.map(({ id, format, ...props }, idx) => {
            const value = id === "self" ? employee : _get(employee, id);
            return (
              <TableCell key={id + idx} {...props}>
                {format ? format(value) : value}
              </TableCell>
            );
          })}
          <TableCell align='center' style={{ width: 48 }}>
            <IconButton
              disableFocusRipple
              type='button'
              disableRipple
              onClick={handleDotsClick(employee)}
              sx={{ padding: 0 }}
            >
              <MoreVertOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [handleDotsClick, selectedRowsIds, employees],
  );

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {employeesTableColumns.map(({ id, label, format, ...props }, idx) => (
                <TableCell key={id + idx} {...props}>
                  {format ? label : label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{UsersElementsRows}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={maxCount}
        rowsPerPage={10}
        page={currentPage}
        rowsPerPageOptions={[10]}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to, count }) => {
          return "Сессии " + from + "-" + to + " из " + count;
        }}
      />
    </>
  );
};
