import React, { useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { addMinutes } from "date-fns";
import cl from "classnames";

// Material UI
import LoadingButton from "@mui/lab/LoadingButton";

// Layouts
import { PopupLayout } from "layouts";
// Components
import { Loader } from "components/Elements";
import { DigitCodeField } from "components/Inputs";
import { CountdownButton } from "components/Buttons";

// Services
import { UsersAPI } from "services/Passport";
import { parseStringToPhone } from "utils/Phone";

// Scss
import styles from "./PhoneConfirm.module.scss";

// Typescript
import { PhoneConfirmProps } from "./PhoneConfirm.props";

export const PhoneConfirm: React.FC<PhoneConfirmProps> = ({
  user,
  isOpen,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  // Дата следующего возможного получения звонка
  const [nextCallAttempt, setNextCallAttempt] = useState<null | Date>(null);
  // Статус звонка пользователю
  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);
  // Индикатор, который показывает, что уже была попытка автоматического подтверждения
  const [isAutoSubmitted, setAutoSubmitStatus] = useState(false);
  // Индикатор, что отправлен запрос к API
  const [isSubmitting, setSubmittingStatus] = useState(false);
  // Референс формы
  const formRef = useRef<HTMLFormElement>(null);

  const { setValue, handleSubmit, control } = useForm<{ code: string }>({ mode: "all", defaultValues: { code: "" } });
  const { code } = useWatch({ control });

  const initCallToPhone = useCallback(async () => {
    if (!user.isPhoneConfirmed) {
      setNextCallAttempt(addMinutes(new Date(), 1));

      return UsersAPI.requestPhoneConfirmation(user.id)
        .then(() => setTimeout(() => setCallStatus("success"), 200))
        .catch(() => setTimeout(() => setCallStatus("error"), 200));
    }
  }, [user]);

  useEffect(() => {
    initCallToPhone();
  }, [initCallToPhone]);

  useEffect(() => {
    if (callStatus === "error") onClose();
  }, [callStatus, onClose]);

  const onConfirmationSubmit: SubmitHandler<{ code: string }> = useCallback(
    async (values) => {
      setSubmittingStatus(true);
      return UsersAPI.confirmSentConfirmation(user.id, {
        confirmationCode: values.code,
        confirmationType: "phone",
      })
        .then(() => {
          onSuccessServerResponse();
          onClose();
        })
        .finally(() => setSubmittingStatus);
    },
    [onClose, onSuccessServerResponse, user.id],
  );

  useEffect(() => {
    if (code && code.length === 4 && !isAutoSubmitted && formRef.current) {
      onConfirmationSubmit({ code });
      setAutoSubmitStatus(true);
    }
  }, [code, isAutoSubmitted, onConfirmationSubmit]);

  const handleCodeChange = (value: string) => {
    setValue("code", value);
  };

  return (
    <PopupLayout isOpen={isOpen} title='Подтверждение телефона' onClose={onClose}>
      <div className={cl(styles["popup__content-wrapper"])}>
        <Loader isActive={callStatus !== "success"} />
        <p className={cl(styles["popup__subtitle"])}>
          На телефон {parseStringToPhone(user.phone).formattedNumber} сейчас поступит звонок. Введите последние 4 цифры
          номера.
        </p>
        <form ref={formRef} onSubmit={handleSubmit(onConfirmationSubmit)} className={cl(styles["popup__form"])}>
          <DigitCodeField value={code ?? ""} codeLength={4} onChange={handleCodeChange} />
          <div className={cl(styles["popup__buttons"])}>
            <LoadingButton
              size='large'
              type='submit'
              variant='contained'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Подтвердить
            </LoadingButton>
            <CountdownButton
              size='large'
              type='button'
              variant='outlined'
              onClick={initCallToPhone}
              disabled={nextCallAttempt === null}
              date={nextCallAttempt ?? new Date()}
              countDownTemplate='m:ss'
            >
              Запросить звонок еще раз
            </CountdownButton>
          </div>
        </form>
      </div>
    </PopupLayout>
  );
};
