import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

// Pages
import { StatusPage } from "pages";

// Services
import { UsersAPI } from "services/Passport";

// Utils
import { resetSchema } from "utils/Schemas";

// Scss
import styles from "./Reset.module.scss";

export const Reset: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({ mode: "all", resolver: yupResolver(resetSchema) });
  const [isFormSubmitted, setFormSubmitStatus] = useState(false);

  const onFormSubmit: SubmitHandler<{ email: string }> = async (values) => {
    return UsersAPI.requestReset(values.email)
      .then(() => setFormSubmitStatus(true))
      .catch((error: AxiosError) => {
        const message = error.response?.data.message ?? "Неизвестная ошибка сервера";

        if (error.response?.status === 400 || error.response?.status === 404) {
          setError("email", { message, type: "validate" });
          return;
        }

        enqueueSnackbar(message ?? "Неизвестная ошибка сервера", { variant: "error" });
      });
  };

  const goToSignInClick = () => navigate("/sign-in");

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Сброс пароля</title>
        <meta name='description' content='Страница входа в личный кабинет РМ Солюшн'></meta>
      </Helmet>

      {isFormSubmitted ? (
        <StatusPage
          title='Восстановление пароля'
          subtitle='На указанный вами e-mail было отправлено письмо, в котором прикреплена ссылка для сброса пароля'
          status='none'
          buttonText='Войти'
          onClick={goToSignInClick}
        />
      ) : (
        <main className={cl(styles["content"])}>
          <h1 className={cl(styles["content__title"])}>Восстановление пароля</h1>
          <span className={cl(styles["content__subtitle"])}>
            Введите адрес e-mail, доступ к которому необходимо восстановить
          </span>
          <form onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["form"])}>
            <TextField
              required
              fullWidth
              type='email'
              label='E-mail'
              {...register("email")}
              error={!!errors["email"]}
              helperText={errors["email"]?.message}
            />
            <div className={cl(styles["form__buttons"])}>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Отправить ссылку
              </LoadingButton>
              <Button size='large' variant='outlined' type='button' onClick={goToSignInClick}>
                Войти
              </Button>
            </div>
          </form>
        </main>
      )}
    </>
  );
};
