import React from "react";
import { Navigate, Route, Routes } from "react-router";

// Layouts
import { AdminRoute, SettingsLayout } from "layouts";

// Children pages
import { Account } from "./Account/Account";
import { Security } from "./Security/Security";
import { Users } from "./Users/Users";
import { Applications } from "./Applications/Applications";
import { Organization } from "./Organization/Organization";
import { Employees } from "./Employees/Employees";

// Redux
import { useAppSelector } from "hooks/redux";

const Settings: React.FC = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userReducer);

  return (
    <SettingsLayout>
      <Routes>
        {/* Пути для обычных пользователей */}
        <Route path='/account' element={<Account />} />
        <Route path='/security' element={<Security />} />

        {/* Пути для администраторов */}
        <Route path='/users' element={<AdminRoute acl={["passport.users.viewer"]} element={<Users />} />} />

        {/* Пути для организаций */}
        {(user?.organizations ?? []).length > 0 && <Route path='/organization' element={<Organization />} />}
        {(user?.organizations ?? []).length > 0 && <Route path='/organization/employees' element={<Employees />} />}
        {(user?.organizations ?? []).length > 0 && (
          <Route path='/organization/applications' element={<Applications />} />
        )}

        <Route path='/*' element={<Navigate to='/account' />} />
      </Routes>
    </SettingsLayout>
  );
};

export default Settings;
