import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cl from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useCopyToClipboard } from "usehooks-ts";
import { useSnackbar } from "notistack";
import _isEmpty from "lodash/isEmpty";

// Material UI
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

// Services
import { ApplicationsAPI, MicroServicesAPI } from "services/Passport";

// Components
import { Loader } from "components/Elements";
import { AccessLevelSearch } from "components/Inputs";
// Layouts
import { PopupLayout } from "layouts";

// Scss
import styles from "./CreateApplicationPopup.module.scss";

// Typescript
import { ApplicationFormFields, CreateApplicationPopupProps } from "./CreateApplicationPopup.props";
import { IApplicationWithPassword, MicroserviceACL } from "types/models";

// Utils
import { applicationSchema } from "utils/Schemas";

export const CreateApplicationPopup: React.FC<CreateApplicationPopupProps> = ({
  isOpen,
  organizationId,
  onSuccessApplicationCreate,
  onClose = () => {},
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  // Вспомогательные утилиты для копирования пароля приложения
  const passwordTextRef = useRef<HTMLSpanElement>(null);
  const [, copy] = useCopyToClipboard();

  // Информация о созданном приложении
  const [createdApplication, setCreatedApplication] = useState<IApplicationWithPassword | null>(null);
  // Выбранный пользователем сервис для приложения
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  // Индикатор, обозначающий загрузку данных в поп-ап окне (при инициализации)
  const [isPending, setPendingStatus] = useState(true);
  // Информация о доступных для выбора сервисах
  const [microservices, setMicroservices] = useState<{ value: string; children: string }[]>([]);

  const {
    setValue,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ApplicationFormFields>({ mode: "all", resolver: yupResolver(applicationSchema) });
  const { serviceId, accessRules } = useWatch({ control });

  const loadMicroservices = useCallback(async () => {
    setPendingStatus(true);

    const microservices = await MicroServicesAPI.findAll();
    setMicroservices(microservices.map((m) => ({ children: m.translation?.title + "", value: m.id })));

    setTimeout(() => setPendingStatus(false), 200);
  }, []);

  useEffect(() => {
    loadMicroservices();
  }, [loadMicroservices]);

  const handleCopyToClipboardClick = async () => {
    if (passwordTextRef) {
      const target = passwordTextRef.current as HTMLSpanElement;
      copy(target.textContent || "");
      enqueueSnackbar("Секретный ключ приложения успешно скопирован в буфер обмена", { variant: "success" });
    }
  };

  const onApplicationCreate: SubmitHandler<ApplicationFormFields> = (values) => {
    setFormSubmitting(true);

    return ApplicationsAPI.create(organizationId, values)
      .then((res) => {
        setCreatedApplication(res);
        onSuccessApplicationCreate();
      })
      .finally(() => setFormSubmitting(false));
  };

  const handleMicroserviceFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue("serviceId", evt.target.value as string);
    setValue("accessRules", []);
  };

  const handleAccessLevelSuggestionSelect = (value: MicroserviceACL) => {
    if (accessRules) {
      setValue("accessRules", (accessRules ?? []).includes(value.id) ? accessRules : [...accessRules, value.id]);
    }
  };

  const handleAccessLevelRemove = useCallback(
    (id: string) => () => {
      if (accessRules) {
        const newAccessRules = accessRules.filter((acl) => acl !== id);
        setValue("accessRules", newAccessRules);
      }
    },
    [accessRules, setValue],
  );

  const SelectedACLComponents = useMemo(
    () =>
      (accessRules ?? []).map((acl) => {
        return <Chip key={acl} label={acl} onDelete={handleAccessLevelRemove(acl)} />;
      }),
    [accessRules, handleAccessLevelRemove],
  );

  return (
    <PopupLayout isOpen={isOpen} onClose={onClose}>
      <h2 className={cl(styles["popup__title"])}>Создать пароль приложения</h2>
      <form onSubmit={handleSubmit(onApplicationCreate)} className={cl(styles["form"])}>
        <Loader isActive={isPending} />

        <TextField
          select
          required
          placeholder='Выберите из списка'
          label='Выберите, к каким данным нужно предоставить доступ'
          value={serviceId ?? ""}
          {...register("serviceId")}
          onChange={handleMicroserviceFieldChange}
          error={!!errors["serviceId"]}
          helperText={errors["serviceId"]?.message}
        >
          {microservices.map((microservice) => (
            <MenuItem key={microservice.value} {...microservice} />
          ))}
        </TextField>

        {!_isEmpty(serviceId) && createdApplication === null && (
          <>
            <TextField
              label='Описание приложения'
              placeholder='Введите название приложения'
              {...register("description")}
              error={!!errors["description"]}
              helperText={errors["description"]?.message}
            />
            <AccessLevelSearch
              filter='all'
              selectedACL={accessRules ?? []}
              allowedServices={serviceId ? [serviceId] : undefined}
              onSuggestionSelect={handleAccessLevelSuggestionSelect}
            />
            <ul className={cl(styles["form__access-list"])}>{SelectedACLComponents}</ul>
          </>
        )}

        {createdApplication === null && (
          <div className={cl(styles["form__buttons"])}>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isFormSubmitting}
              disabled={_isEmpty(serviceId) || (accessRules ?? []).length === 0 || isFormSubmitting}
            >
              Создать
            </LoadingButton>
            <Button fullWidth size='large' type='button' variant='outlined' onClick={onClose}>
              Отменить
            </Button>
          </div>
        )}

        {createdApplication !== null && (
          <div className={cl(styles["form__password-box"])}>
            <span ref={passwordTextRef} className={cl(styles["form__password"])}>
              {createdApplication?.password}
            </span>
            <Button fullWidth size='large' type='button' variant='outlined' onClick={handleCopyToClipboardClick}>
              Скопировать
            </Button>
          </div>
        )}
      </form>
    </PopupLayout>
  );
};
