import React, { useCallback, useState } from "react";
import { usePopupManager } from "react-popup-manager";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import Button from "@mui/material/Button";

// Services
import { ApplicationsAPI } from "services/Passport";

// Images
import { ReactComponent as LockImageSVG } from "assets/images/lock.svg";
// Layouts
import { ContentBox } from "layouts";
import { Loader } from "components/Elements";
// Blocks
import { ApplicationsTable } from "blocks";
// Popups
import { CreateApplicationPopup } from "popups";

// Redux
import { useAppSelector } from "hooks/redux";

// Scss
import styles from "./Applications.module.scss";

// Typescript
import { IApplication } from "types/models";

export const Applications: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const popupManager = usePopupManager();

  const { user } = useAppSelector((state) => state.userReducer);

  const [maxCount, setMaxCount] = useState(0);
  const [isPending, setPendingStatus] = useState(true);
  const [applications, setApplications] = useState<IApplication[]>([]);

  const loadApplications = useCallback(
    async (page: number) => {
      setPendingStatus(true);

      await ApplicationsAPI.findAllByOrganizationId(user?.organizations[0]?.id + "", page + 1)
        .then(({ rows, count }) => {
          setApplications(rows);
          setMaxCount(count);
        })
        .finally(() => setTimeout(() => setPendingStatus(false), 200));
    },
    [user?.organizations],
  );

  const handleNewApplicationClick = () => {
    if (user && user?.organizations[0]) {
      popupManager.open(CreateApplicationPopup, {
        organizationId: user.organizations[0].id + "",
        onSuccessApplicationCreate: async () => {
          enqueueSnackbar("Приложение успешно зарегистрировано в системе и доступно для работы с API", {
            variant: "success",
          });

          await loadApplications(0);
        },
      });
    }
  };

  return (
    <>
      <Loader isActive={isPending} />
      <div className={cl(styles["applications"])}>
        <ContentBox expanded title='Приложения организации'>
          <div className={cl(styles["applications__info"])}>
            <LockImageSVG className={cl(styles["applications__image"])} />
            <p className={cl(styles["applications__text"])}>
              Для использования API нужно создать приложение, которое будет использоваться для авторизации в системе
            </p>
            <Button
              size='large'
              type='button'
              variant='contained'
              onClick={handleNewApplicationClick}
              className={cl(styles["applications__button"])}
            >
              Создать приложение
            </Button>
          </div>
        </ContentBox>

        <ContentBox expanded title='Сохраненные пароли приложений'>
          <div className={cl(styles["applications__table"])}>
            <ApplicationsTable applications={applications} maxCount={maxCount} onTableChange={loadApplications} />
          </div>
        </ContentBox>
      </div>
    </>
  );
};
