import React, { useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { addMinutes } from "date-fns";
import cl from "classnames";

// Material UI
import LoadingButton from "@mui/lab/LoadingButton";

// Layouts
import { PopupLayout } from "layouts";
// Components
import { Loader } from "components/Elements";
import { DigitCodeField } from "components/Inputs";
import { CountdownButton } from "components/Buttons";

// Services
import { UsersAPI } from "services/Passport";

// Scss
import styles from "./EmailConfirm.module.scss";

// Typescript
import { EmailConfirmProps } from "./EmailConfirm.props";

export const EmailConfirm: React.FC<EmailConfirmProps> = ({
  user,
  isOpen,
  onSuccessServerResponse,
  onClose = () => {},
}): JSX.Element => {
  // Дата следующего возможного получения письма
  const [nextEmailAttempt, setNextEmailAttempt] = useState<null | Date>(null);
  // Индикатор, показывающий статус отправки письма
  const [emailSendStatus, setEmailSendStatus] = useState<"success" | "error" | null>(null);
  // Индикатор, который показывает, что уже была попытка автоматического подтверждения
  const [isAutoSubmitted, setAutoSubmitStatus] = useState(false);
  // Индикатор, что отправлен запрос к API
  const [isSubmitting, setSubmittingStatus] = useState(false);
  // Референс формы
  const formRef = useRef<HTMLFormElement>(null);

  const { setValue, handleSubmit, control } = useForm<{ code: string }>({ mode: "all" });
  const { code } = useWatch({ control });

  const initEmailSend = useCallback(async () => {
    if (!user.isEmailConfirmed) {
      setNextEmailAttempt(addMinutes(new Date(), 1));

      return UsersAPI.requestEmailConfirmation(user.id)
        .then(() => setTimeout(() => setEmailSendStatus("success"), 200))
        .catch(() => setTimeout(() => setEmailSendStatus("error"), 200));
    }
  }, [user]);

  useEffect(() => {
    initEmailSend();
  }, [initEmailSend]);

  useCallback(() => {
    if (emailSendStatus === "error") onClose();
  }, [emailSendStatus, onClose]);

  const onConfirmationSubmit: SubmitHandler<{ code: string }> = useCallback(
    async (values) => {
      setSubmittingStatus(true);

      return UsersAPI.confirmSentConfirmation(user.id, {
        confirmationCode: values.code,
        confirmationType: "email",
      })
        .then(() => {
          onSuccessServerResponse();
          onClose();
        })
        .finally(() => setSubmittingStatus(false));
    },
    [onClose, onSuccessServerResponse, user.id],
  );

  useEffect(() => {
    if (code && code.length === 6 && !isAutoSubmitted && formRef.current) {
      onConfirmationSubmit({ code });
      setAutoSubmitStatus(true);
    }
  }, [code, isAutoSubmitted, onConfirmationSubmit]);

  const handleCodeChange = (value) => {
    setValue("code", value);
  };

  return (
    <PopupLayout isOpen={isOpen} title='Подтверждение email' onClose={onClose}>
      <div className={cl(styles["popup__content-wrapper"])}>
        <Loader isActive={emailSendStatus !== "success"} />
        <p className={cl(styles["popup__subtitle"])}>
          На почту {user.email} было отправлено письмо с кодом для подтверждения.
        </p>
        <form ref={formRef} onSubmit={handleSubmit(onConfirmationSubmit)} className={cl(styles["popup__form"])}>
          <DigitCodeField value={code ?? ""} onChange={handleCodeChange} codeLength={6} />
          <div className={cl(styles["popup__buttons"])}>
            <LoadingButton
              size='large'
              type='submit'
              variant='contained'
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Подтвердить
            </LoadingButton>
            <CountdownButton
              size='large'
              type='button'
              variant='outlined'
              onClick={initEmailSend}
              disabled={nextEmailAttempt === null}
              date={nextEmailAttempt ?? new Date()}
              countDownTemplate='m:ss'
            >
              Запросить еще раз
            </CountdownButton>
          </div>
        </form>
      </div>
    </PopupLayout>
  );
};
