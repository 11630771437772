import axios from "axios";

// Typescript
import { IUser } from "types/models";
import { MultiResponse } from "types/MultiResponse";

// Services
import { GlobalAPI } from "../GlobalAPI";

class UsersAPI extends GlobalAPI {
  async create(userData: Record<string, unknown>) {
    return axios.post(`${process.env.REACT_APP_PASSPORT_BASE_URL}/users`, userData).then((res) => res.data);
  }

  async findMe(): Promise<IUser> {
    return this.axios.get("/users/me").then((res) => res.data);
  }

  async findAll(page = 1, amount = 100, scope: string[] = []): Promise<MultiResponse<IUser>> {
    return this.axios.get("/users", { params: { page, amount, scope: scope } }).then((res) => res.data);
  }

  updateUserACL(userId: string | number, acl: string[]) {
    return this.axios.patch(`/users/${userId}/acl`, acl).then((res) => res.data);
  }

  async requestReset(email: string) {
    return axios
      .get(this.axios.defaults.baseURL + "/users/actions/request-reset", {
        headers: { ...this.staticHeaders },
        params: { email },
      })
      .then((res) => res.data);
  }

  async changeUserStatus(userId: number | string, action: "block" | "unblock"): Promise<IUser> {
    return this.axios.delete(`/users/${userId}?action=${action}`).then((res) => res.data);
  }

  async checkConfirmationExistence(code: string) {
    return axios
      .get(this.axios.defaults.baseURL + "/users/confirmations/actions/check", {
        headers: { ...this.staticHeaders },
        params: { code },
      })
      .then((res) => res.data)
      .catch(this._handleError);
  }

  async resetPassword(code: string, passwordData: { password: string }) {
    return axios
      .patch(`${this.axios.defaults.baseURL}/users/actions/reset-password`, passwordData, {
        headers: { ...this.staticHeaders },
        params: { code },
      })
      .then((res) => res.data)
      .catch(this._catchErrorWithoutNotification);
  }

  async update(userId: string, profileData: Record<string, unknown>) {
    return this.axios.patch(`/users/${userId}`, profileData).then((res) => res.data);
  }

  async appendPhoto(userId: string | number, formData: FormData): Promise<IUser> {
    return this.axios
      .post(`/users/${userId}/photo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => res.data);
  }

  async changeStatus(userId: string | number, action: "block" | "unblock", body: { reason: string }): Promise<IUser> {
    return this.axios.patch(`/users/${userId}/status`, body, { params: { action } }).then((res) => res.data);
  }

  async changePassword(userId: number | string, passwordData: Record<string, string>) {
    return this.axios.patch(`/users/${userId}/password`, passwordData).then((res) => res.data);
  }

  async requestEmailConfirmation(userId: string | number) {
    return this.axios.get(`/users/${userId}/confirmations/email`).then((res) => res.data);
  }

  async requestPhoneConfirmation(userId: string | number) {
    return this.axios.get(`/users/${userId}/confirmations/phone`).then((res) => res.data);
  }

  async confirmSentConfirmation(
    userId: string | number,
    params: { confirmationCode: string; confirmationType: "email" | "phone" },
  ) {
    return this.axios.patch(`/users/${userId}/confirmations`, null, { params }).then((res) => res.data);
  }
}

export default new UsersAPI();
