import React, { useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import cl from "classnames";

// Material UI
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

// Services
import { DaDataAPI, OrganizationsAPI } from "services";

// Scss
import styles from "./CreateOrganization.module.scss";

// Typescript
import { OrganizationCreationFields } from "./CreateOrganization.props";

// Utils
import { parseStringToPhone } from "utils/Phone";
import { organizationSchema } from "utils/Schemas";

export const CreateOrganization: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<OrganizationCreationFields>({ mode: "all", resolver: yupResolver(organizationSchema) });
  const { fullName, shortName, kpp, inn, phone, eula } = useWatch({ control });

  // Состояние чекбокса подтверждения прав
  const [isPending, setPendingStatus] = useState(false);

  const onFormSubmit: SubmitHandler<OrganizationCreationFields> = async (values) => {
    setPendingStatus(true);

    await OrganizationsAPI.create(values)
      .then((organization) => {
        enqueueSnackbar(`Организация ${organization.details.shortName} успешно зарегистрирована в системе`, {
          variant: "success",
        });

        navigate("/settings/organization");
      })
      .finally(() => setPendingStatus(false));
  };

  const onInnFieldBlur = async () => {
    if (inn) {
      const { suggestions } = await DaDataAPI.findOrganization(inn);

      if (suggestions.length > 0) {
        const organizationInfo = suggestions[0];

        setValue("inn", organizationInfo.data.inn);
        setValue("kpp", organizationInfo.data.kpp);
        setValue("shortName", organizationInfo.data.name.short_with_opf);
        setValue("fullName", organizationInfo.data.name.full_with_opf);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>РМС Паспорт | Создание организации</title>
        <meta name='description' content='Страница регистрации юридического лица в системе РМС' />
      </Helmet>

      <div className={cl(styles["page"])}>
        <h1 className={cl(styles["page__title"])}>Данные организации</h1>
        <form ref={formRef} onSubmit={handleSubmit(onFormSubmit)} className={cl(styles["form"])}>
          <TextField
            required
            label='Полное название организации'
            {...register("fullName")}
            value={fullName ?? ""}
            error={!!errors["fullName"]}
            helperText={errors["fullName"]?.message}
            className={cl(styles["form__field_for-fullName"])}
          />
          <TextField
            required
            label='Краткое название организации'
            {...register("shortName")}
            value={shortName ?? ""}
            error={!!errors["shortName"]}
            helperText={errors["shortName"]?.message}
            className={cl(styles["form__field_for-shortName"])}
          />
          <TextField
            required
            label='ИНН'
            {...register("inn")}
            value={inn ?? ""}
            error={!!errors["inn"]}
            helperText={errors["inn"]?.message}
            onBlur={onInnFieldBlur}
          />
          <TextField
            required
            label='КПП'
            {...register("kpp")}
            value={kpp ?? ""}
            error={!!errors["kpp"]}
            helperText={errors["kpp"]?.message}
          />
          <TextField
            required
            label='БИК'
            {...register("bik")}
            error={!!errors["bik"]}
            helperText={errors["bik"]?.message}
          />
          <TextField
            required
            label='Расчетный счет'
            {...register("accountNumber")}
            error={!!errors["accountNumber"]}
            helperText={errors["accountNumber"]?.message}
            className={cl(styles["form__field_for-accountNumber"])}
          />
          <TextField
            required
            label='Юридический адрес'
            {...register("legalAddress")}
            error={!!errors["legalAddress"]}
            helperText={errors["legalAddress"]?.message}
            className={cl(styles["form__field_for-address"])}
          />
          <TextField
            required
            label='Почтовый индекс'
            {...register("postcode")}
            error={!!errors["postcode"]}
            helperText={errors["postcode"]?.message}
          />
          <TextField
            required
            label='Контактный телефон'
            {...register("phone")}
            placeholder='+7 ( ___ ) ___ - __ - __'
            error={!!errors["phone"]}
            helperText={errors["phone"]?.message}
            value={parseStringToPhone(phone ?? "").formattedNumber}
          />
          <TextField
            required
            label='Фактический адрес'
            {...register("actualAddress")}
            error={!!errors["actualAddress"]}
            helperText={errors["actualAddress"]?.message}
            className={cl(styles["form__field_for-address"])}
          />
          <TextField
            required
            label='E-mail организации'
            {...register("email")}
            error={!!errors["email"]}
            helperText={errors["email"]?.message}
            className={cl(styles["form__field_for-email"])}
          />
          <div className={cl(styles["form__footer"])}>
            <FormControlLabel
              required
              control={<Checkbox {...register("eula")} />}
              label='Я подтверждаю, что являюсь уполномоченным представителем организации'
            />
            <div className={cl(styles["form__buttons"])}>
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                className={cl(styles["form__button"])}
                disabled={!isValid || !eula || isPending}
                loading={isPending}
              >
                Создать
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
