import React, { useEffect, useMemo, useState } from "react";
import cl from "classnames";

// Services
import { MicroServicesAPI } from "services/Passport";

// Components
import { Loader, MicroService } from "components/Elements";

// Scss
import styles from "./Home.module.scss";

// Typescript
import { IMicroservice } from "types/models";

export const Home: React.FC = (): JSX.Element => {
  const [isPending, setPendingStatus] = useState(true);

  const [services, setServices] = useState<IMicroservice[]>([]);

  const loadServices = async () => {
    setPendingStatus(true);

    const result = await MicroServicesAPI.findAll();
    setServices(result);

    setTimeout(() => setPendingStatus(false), 100);
  };

  useEffect(() => {
    loadServices();
  }, []);

  const Services = useMemo(() => services.map((service) => <MicroService key={service.id} {...service} />), [services]);

  return (
    <main className={cl(styles["services"])}>
      <Loader isActive={isPending} />
      <h1 className={cl(styles["services__title"])}>Сервисы</h1>
      <ul className={cl(styles["services__list"])}>{Services}</ul>
    </main>
  );
};
